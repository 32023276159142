/**
***************************************************************************
* NAME 			: EmployeeCardHR
* PROPS	 		: children (employee name)
*				: id (employeeId)
*				: firstName (of employee)
*				: lastName (of employee)
*		 		: width (optional - default 117)
*		 		: height (optional - default 85)
* RETURNS 		: Styled link to direct a user in the HR role to
*				: employee time off or acccount details
* LAST MODIFIED 	: May 22, 2022
***************************************************************************
*/

import { Link } from 'react-router-dom';
// import classes from './OrangeCard.module.css';

const EmployeeCardHR = (props) => {
//	const API_URL = "https://qxwuuqemnacfhweocuzr.mlinc.com";
	const name = props.firstName.toLowerCase() + ' ' + props.lastName.toLowerCase();
	const firstInitial = props.firstName.charAt(0);
	const imgsrc = '/img/headshots/' + firstInitial.toLowerCase() + props.lastName.toLowerCase() + '.jpg';
	const totURL = '/time-off-tracker/' + props.id;
	const accountURL = '/account/' + props.id;

	return (
		<>
			<div className="emp-card hr">
				<div class="headshot">
					<img src={imgsrc} alt={name} width={props.width || '117'} height={props.height || '85'} />
				</div>
				<div class="info">
					<p class="name">{name}</p>
					<div className="flexwrap">
						<Link to={totURL}><img src="/img/tracker-icn.svg" alt="" width="26" height="26" className="tracker" /></Link>
						<Link to={accountURL}><img src="/img/acct-icn.svg" alt="" width="26" height="26" className="account" /></Link>
					</div>
				</div>
			</div>
		</>
	)
}

export default EmployeeCardHR;