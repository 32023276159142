import { useState, useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AuthContext from '../../../context/auth-context'
import NotificationContext from '../../../context/notification-context';
import LoadingSpinner from '../../UI/LoadingSpinner';
import useInput from '../../../hooks/use-input';
import useHttp from '../../../hooks/use-http';
import { updateAddress } from '../../../api/account';
import { getStates } from '../../../api/employee';
// import TextField from '@material-ui/core/TextField';

const AddressForm = (props) => {
	const params = useParams();

	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);
	const [states, setStates] = useState([]);
	const [formProcessing, setFormProcessing] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const address2Ref = useRef();
	const stateRef = useRef();

	let isHR = false;
	if (typeof params.employeeId !== 'undefined') {
		if (!isNaN(params.employeeId) && (params.employeeId > 0)) {
			isHR = true;
		}
	}

	const { sendRequest, status, data, error } = useHttp(updateAddress);
	const { sendRequest: stateRequest, status: stateStatus, data: stateData, error: stateError } = useHttp(getStates);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					error
				)
			} else if (!data['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					data['status_code'] + ': ' + data['status']
				)
			} else {
				if (data['status_code'] == 200) {
					// Password reset
					notifyCtx.displayNotification(
						"OK",
						'The address has been updated'
					)

					// resetAddress();
					// resetCity();
					// resetZip();

					// Return to log in
					props.onUpdate(true);
				} else {
					setErrorMessage(data['status']);
				}
			}
		}
	}, [status, error, data]);

	/**
	**********
	INIT
	**********
	*/
	useEffect(() => {
		if (stateStatus === 'completed') {
			if (stateError !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					stateError
				)
			} else if (!stateData['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					stateData['status_code'] + ': ' + stateData['status']
				)
			} else {
				// console.log('STATES');
				// console.log(stateData['data']);

				setStates(stateData['data']);
			}
			// setIsLoading(false);
		}
	}, [stateStatus, stateError, stateData]);

	useEffect(() => {
		const requestData = {
			token: authCtx.token
		};

		stateRequest(requestData);
		addressDefault(props.data.address);
		cityDefault(props.data.city);
		zipDefault(props.data.zip);
	}, []);

	// Requirement types
	const isRequired = value => value && (value != '') && (value != 0);
	const isZip = value => value && (value.length == 5) && (!isNaN(value));

	const {
		value: enteredAddress,
		isValid: addressIsValid,
		hasError: addressHasError,
		valueChangeHandler: addressChangedHandler,
		inputBlurHandler: addressBlurHandler,
		valueSubmitHandler: addressSubmitHandler,
		defaultValue: addressDefault,
		reset: resetAddress
	} = useInput(isRequired); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	const {
		value: enteredCity,
		isValid: cityIsValid,
		hasError: cityHasError,
		valueChangeHandler: cityChangedHandler,
		inputBlurHandler: cityBlurHandler,
		valueSubmitHandler: citySubmitHandler,
		defaultValue: cityDefault,
		reset: resetCity
	} = useInput(isRequired); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	const {
		value: enteredZip,
		isValid: zipIsValid,
		hasError: zipHasError,
		valueChangeHandler: zipChangedHandler,
		inputBlurHandler: zipBlurHandler,
		valueSubmitHandler: zipSubmitHandler,
		defaultValue: zipDefault,
		reset: resetZip
	} = useInput(isZip); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	let formIsValid = false;
	if (addressIsValid && cityIsValid && zipIsValid) { // And any other form fields
		formIsValid = true;
	}

	const submitHandler = event => {
		event.preventDefault();

		setFormProcessing(true);

		// Added (if form is able to be submitted first before error checking)
		addressSubmitHandler();
		citySubmitHandler();
		zipSubmitHandler();

		if (!formIsValid) {
			setErrorMessage(null);
			setFormProcessing(false);
			return;
		}

		let formData = {
			token: authCtx.token,
			address: enteredAddress,
			address2: address2Ref.current.value,
			city: enteredCity,
			state: stateRef.current.value,
			zip: enteredZip
		};

		if (isHR) {
			formData = {
				token: authCtx.token,
				address: enteredAddress,
				address2: address2Ref.current.value,
				city: enteredCity,
				state: stateRef.current.value,
				zip: enteredZip,
				employee: params.employeeId
			};
		}
		// console.log('FORM DATA');
		// console.log(formData);

		sendRequest(formData);

		setFormProcessing(false);
		// lnameRef.current.value = ''; // Not ideal but doable (because you're directly manipulating the DOM)
	}

	const cancelHandler = () => {
		props.onUpdate();
	}

	const addressInputClasses = addressHasError ? 'form-control invalid' : 'form-control';
	const cityInputClasses = cityHasError ? 'form-control invalid' : 'form-control';
	const zipInputClasses = zipHasError ? 'form-control invalid' : 'form-control';

	return (
		<div className="formwrap">
			{
				<p className="error-text">{errorMessage}</p>
			}
			<form onSubmit={submitHandler}>
				<div className={addressInputClasses}>
					<label htmlFor='address'>address</label>
					<input
						type="text"
						name="address"
						id="address"
						maxLength="50"
						onChange={addressChangedHandler}
						onBlur={addressBlurHandler}
						value={enteredAddress}
					/>
					{addressHasError &&
						<p className="error-text">Required</p>
					}
				</div>
				<div>
					<label htmlFor='address2'>address 2</label>
					<input
						type="text"
						name="address2"
						id="address2"
						maxLength="50"
						defaultValue={props.data.address2}
						ref={address2Ref}
					/>
				</div>
				<div className={cityInputClasses}>
					<label htmlFor='city'>city</label>
					<input
						type="text"
						name="city"
						id="city"
						maxLength="50"
						onChange={cityChangedHandler}
						onBlur={cityBlurHandler}
						value={enteredCity}
					/>
					{cityHasError &&
						<p className="error-text">Required</p>
					}
				</div>
				<div className="customselect">
					<label htmlFor='state'>state</label>
					<select name="state" id="state" ref={stateRef}>
						{states.map(state =>
							<> 
								{state.stateId != props.data.stateId &&
									<option key={state.stateId} value={state.stateId}>{state.name}</option>
								}
								{state.stateId == props.data.stateId &&
									<option key={state.stateId} value={state.stateId} selected="selected">{state.name}</option>
								}
							</>
						)}
					</select>
				</div>
				<div className={zipInputClasses}>
					<label htmlFor='zip'>zip</label>
					<input
						type="text"
						name="zip"
						id="zip"
						maxLength="5"
						onChange={zipChangedHandler}
						onBlur={zipBlurHandler}
						value={enteredZip}
					/>
					{zipHasError &&
						<p className="error-text">5-digit ZIP Code required</p>
					}
				</div>
				<div>
					<p>Human Resources will be notified of the change on submit</p>
				</div>
				<div className="button-wrap">
					{ formProcessing &&
						<div>
							<LoadingSpinner />
						</div>
					}
					{ !formProcessing &&
						<>
							<button type="button" className="secondary" onClick={cancelHandler} disabled={formProcessing}>cancel</button>
							<button type="submit" disabled={formProcessing}>update</button>
						</>
					}
				</div>
			</form>
		</div>
	)
}

export default AddressForm;