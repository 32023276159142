import { useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../context/auth-context';
import NotificationContext from '../../context/notification-context';
import useHttp from '../../hooks/use-http';
import { getEmployees, getRole } from '../../api/employee';
import EmployeeCard from '../../components/UI/EmployeeCard';
import EmployeeCardHR from '../../components/UI/EmployeeCardHR';
import LoadingSpinner from '../../components/UI/LoadingSpinner';

const LogicianLookup = () => {
	// Set header information and body classes
	document.title = "Logician Lookup | Time Off Tracker";
	document.body.className = "logician-lookup";

	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);

	const [role, setRole] = useState('');
	const [allEmployees, setAllEmployees] = useState([]);
	const [showEmployees, setShowEmployees] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	// const [pageAccess, setPageAccess] = useState(false);
	const searchRef = useRef('');
	let showArrary = [];
	let url = '';

	const { sendRequest, status, data, error } = useHttp(getEmployees, true);
	const { sendRequest: roleRequest, status: roleStatus, data: roleData, error: roleError } = useHttp(getRole, true);

	/**
	**********
	INIT
	**********
	*/
	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					error
				)
			} else if (!data['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					data['status_code'] + ': ' + data['status']
				)
			} else {
				// console.log('EMPLOYEE DATA');
				// console.log(data['data']);

				// setPageAccess(true);
				setAllEmployees(data['data']);
				setShowEmployees(data['data']);
			}
			setIsLoading(false);

			// Reset timeout
			authCtx.updateExpiration();
		}
	}, [status, error, data]);

	useEffect(() => {
		if (roleStatus === 'completed') {
			if (roleError !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					roleError
				)
			} else if (!roleData['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					roleData['status_code'] + ': ' + roleData['status']
				)
			} else {
				// console.log('ROLE DATA');
				// console.log(roleData['data']);

				setRole(roleData['data']['role']);
			}
		}
	}, [roleStatus, roleError, roleData]);

	useEffect(() => {
		const requestData = {
			token: authCtx.token
		};
	
		sendRequest(requestData);
		roleRequest(requestData);
	}, [sendRequest, roleRequest]);

	/**
	**********
	HANDLERS
	**********
	*/
	function searchHandler(event) {
		event.preventDefault();

		showArrary = allEmployees.filter(employee => employee.name.toLowerCase().indexOf(searchRef.current.value) != -1);
		// console.log(showArrary);

		setShowEmployees(showArrary);
	}

	return (
		<>
			<header role="banner">
				<div className="constrained">
					<h1>logician lookup</h1>
				</div>
			</header>

			<div className="breadcrumb">
				<div className="constrained">
					<ul>
						<li><Link to="/">home</Link></li>
						<li>logician lookup</li>
					</ul>
				</div>
			</div>

			<main>
				<div className="constrained">
					<div className="flexwrap">
						{
							isLoading && (
								<div>
									<LoadingSpinner />
								</div>
							)
						}
						{!isLoading && (
							<>
								<form onSubmit={searchHandler}>
									<div className="emplistsearch">
										<input type='text' id='search' onChange={searchHandler} ref={searchRef} placeholder="I'm looking for..." />
									</div>
								</form>
	
								{(showEmployees.length == 0) &&
									<div>No employees match your search criteria</div>
								}
	
								<div className="flexwrap_empcards">
									{ ((role == 'Human Resources') || (role == 'Administrator')) &&
										showEmployees.map(employee => (
											<EmployeeCardHR
												key={employee.employeeId}
												id={employee.employeeId}
												firstName={employee.firstName}
												lastName={employee.lastName}
											/>
										))
									}
									{ ((role != 'Human Resources') && (role != 'Administrator')) &&
										showEmployees.map(employee => (
											<EmployeeCard
												key={employee.employeeId}
												id={employee.employeeId}
												firstName={employee.firstName}
												lastName={employee.lastName}
												url={url}
											/>
										))
									}
								</div>
							</>
							)
						}
					</div>
				</div>
			</main>
		</>
	)
}

export default LogicianLookup;