import { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom'; // Sets css class on the active anchor item
import AuthContext from '../../../context/auth-context';
import NotificationContext from '../../../context/notification-context';
import useHttp from '../../../hooks/use-http';
import { getRole } from '../../../api/employee';
import LinkCard from '../../UI/LinkCard';
// import classes from './NavigationCallouts.module.css';

const NavigationCallouts = () => {
	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);

	const [role, setRole] = useState('');

	const { sendRequest: roleRequest, status: roleStatus, data: roleData, error: roleError } = useHttp(getRole, true);

	/**
	**********
	ROLE
	**********
	*/
	useEffect(() => {
		if (roleStatus === 'completed') {
			if (roleError !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					roleError
				)
			} else if (!roleData['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					roleData['status_code'] + ': ' + roleData['status']
				)
			} else {
				// console.log('ROLE DATA');
				// console.log(roleData['data']);

				setRole(roleData['data']['role']);
			}
		}
	}, [roleStatus, roleError, roleData]);

	useEffect(() => {
		if ((typeof authCtx.token !== 'undefined') && (authCtx.trustedBrowser)) {
			if (authCtx.token !== null) {
				const requestData = {
					token: authCtx.token
				};

				roleRequest(requestData);
			}
		}
	}, [roleRequest, authCtx.token, authCtx.trustedBrowser]);


	return (
		<div className="pageitem column links">
			<ul>
				{ ((role == 'Human Resources') || (role == 'Administrator')) &&
					<li><LinkCard to="/logician-lookup/" img="logician-icn-white.svg">logician lookup</LinkCard></li>
				}
				{ ((role == 'Human Resources') || (role == 'President') || (role == 'Administrator')) &&
					<li><LinkCard to="/reports/" img="reports-icn-white.svg">reports</LinkCard></li>
				}
				{ ((role == 'President') || (role == 'Administrator')) &&
					<li><LinkCard to="/send-sms/" img="sms-icn-white.svg">send sms</LinkCard></li>
				}
				<li><LinkCard to="/account/" img="acct-icn-white.svg">account</LinkCard></li>
				{/*
				<li><LinkCard to="/hr-stuff/" img="hrstuff-icn-white.svg">hr stuff</LinkCard></li>
				<li><LinkCard to="/tech-know/" img="techknow-icn-white.svg">tech know</LinkCard></li>
				<li><LinkCard to="/resource-full/" img="resources-icn-white.svg">resource-full</LinkCard></li>
				<li><LinkCard to="/workflow/" img="workflow-icn-white.svg">workflow</LinkCard></li>
				*/}
			</ul>
		</div>
	)
}

export default NavigationCallouts;
