/**
***************************************************************************
* NAME 			: PendingApprovals
* PROPS	 		: onChangeApprovals (approvalHandler)
* RETURNS 		: Displays the header and calls for the details
*				: of individual requests
* LAST MODIFIED 	: February 17, 2022
***************************************************************************
*/

import { useState, useEffect, useContext } from 'react';
import AuthContext from '../../../context/auth-context';
import NotificationContext from '../../../context/notification-context';
import useHttp from '../../../hooks/use-http';
import { getTimeOffRequests } from '../../../api/time-off-tracker';
import PendingApproval from './PendingApproval';

const PendingApprovals = (props) => {
	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);

	const [requests, setRequests] = useState([]);
	const [isLoading, setIsLoading] = useState(true); // Not currently used
	const [errorMessage, setErrorMessage] = useState(null); // Not currently used for display

	const { sendRequest, status, data, error } = useHttp(getTimeOffRequests, true);

	const requestData = {
		token: authCtx.token
	};

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					error
				)
				setErrorMessage(error);
			} else if (!data['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					data['status_code'] + ': ' + data['status']
				)
			} else {
				// console.log('REQUESTS DATA');
				// console.log(data['data']);
				setRequests(data['data']);
			}
			setIsLoading(false);
		}
	}, [status, error, data]);

	useEffect(() => {
		sendRequest(requestData);
	}, [sendRequest]);

	const approvalHandler = () => {
		setIsLoading(true);
		sendRequest(requestData);
		props.onChangeApprovals();
	};

	return (
		<div className="pendingapprovals">
			<h2>pending approvals</h2><br />
			
			{ !isLoading &&
				 requests.map(request => {
					let requestData = {
						id: request.employeeId,
						name: request.name,
						requestId: request.timeOffRequestId
					};
					return <PendingApproval key={request.timeOffRequestId} data={requestData} onChangeApprovals={approvalHandler} />
				})
			}
		</div>
	)
}

export default PendingApprovals;