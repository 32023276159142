import { useState, useEffect } from 'react';

let tickTimer;

const DateTime = (props) => {
	// console.log('DATETIME PROPS');
	// console.log(props.content);

	const [currentDate, setCurrentDate] = useState(null);
	const [currentTime, setCurrentTime] = useState(null);
	const [currentDay, setCurrentDay] = useState(null);
	const [currentDateAttr, setCurrentDateAttr] = useState(null);
	const [currentTimeAttr, setCurrentTimeAttr] = useState(null);

	const tick = (timeDifference) => {
		// To sync local with the server clock, this will run every second until it's on the minute
// console.log('TICK');
		const today = new Date(new Date().getTime() - timeDifference);
			
		const seconds = today.getSeconds();
		let month = today.getMonth() + 1;
		let day = today.getDate();
		let year = today.getFullYear() - 2000;
		let fullyear = today.getFullYear();
		let hours = today.getHours();
		let minutes = today.getMinutes();
		let dayofweek = today.getDay();
		let marker = "am";

		// Format Date
		if (month < 10)
			month = '0' + month;
		if (day < 10)
			day = '0' + day;
		if (year < 10)
			year = '0' + year;

		const dayofweekAbbr = ['sun', 'mon', 'tues', 'wed', 'thurs', 'fri', 'sat'];
		dayofweek = dayofweekAbbr[dayofweek];

		// Format Time
		if (hours > 12) {
			hours = hours - 12;
			marker = "pm";
		} else if (hours == 12) {
			marker = "pm";
		} else if (hours == 0) {
			hours = 12;
		}

		if (hours < 10)
			hours = '0' + hours;
		if (minutes < 10)
			minutes = '0' + minutes;

		setCurrentTime(hours + ':' + minutes + ' ' + marker);
		setCurrentDate(month + '.' + day + '.' + year);
		setCurrentDay(dayofweek);
		setCurrentTimeAttr(hours + ':' + minutes);
		setCurrentDateAttr(fullyear + '-' + month + '-' + day);

		if (seconds == 0) {
// console.log('TICK MINUTE: ' + minutes);
			// Recursive call, keeps the clock ticking.
			clearTimeout(tickTimer);
			tickTimer = setTimeout(() => tick(timeDifference), 60000);

			// Only update the display twice per hour
			// if ((minutes == 1) || (minutes == 31))
			// 	updateWeather(startPath);

		} else {
// console.log('TICK SECONDS: ' + seconds);
			// Recursive call, keeps the clock ticking.
			clearTimeout(tickTimer);
			tickTimer = setTimeout(() => tick(timeDifference), 1000);
		}
	}

	const clock = (serverClock) => {
		const today = new Date();
		const todaysYear = today.getFullYear() - 2000;
		const todaysMonth = today.getMonth() + 1;
		
		const sToday = new Date(serverClock);
		const sTodaysYear = sToday.getFullYear() - 2000;
		const sTodaysMonth = sToday.getMonth() + 1;
		
		//Computes the time difference between the client computer and the server.
		const serverDate = (new Date(sTodaysYear, sTodaysMonth, sToday.getDate(), sToday.getHours(), sToday.getMinutes(), sToday.getSeconds())).getTime();
		const clientDate = (new Date(todaysYear, todaysMonth, today.getDate(), today.getHours(), today.getMinutes(), today.getSeconds())).getTime();
		
		tick(clientDate - serverDate);
	}

	useEffect(() => {
// console.log('DATETIME USEEFFECT');
		clock(props.content);
	}, [props.content]); // Adding clock as a dependancy causes it not to work

	return (
		<div className="day">
			<p className="today">{currentDay}</p>
			<div className="date-time">
				<time datetime={currentTimeAttr} className="time"><img src="/img/clock-icn.svg" alt="" width="16" height="16" /> {currentTime}</time>
				<time datetime={currentDateAttr} className="date"><img src="/img/calendar-icn.svg" alt="" width="16" height="16" /> {currentDate}</time>
			</div>
		</div>
	)
}

export default DateTime;