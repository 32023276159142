const API_BASE_URL = process.env.REACT_APP_PHP_API_BASE_URL;
const STRAPI_BASE_URL = 'https://qxwuuqemnacfhweocuzr.mlinc.com';

export async function getDirectContent(pageData) {
	// console.log('PAGEDATA:');
	// console.log(pageData);
	const response = await fetch(`${API_BASE_URL}/${pageData.url}`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	// Check for unsuccessful response
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the page content.');
	}

	const data = await response.json();

	// const transformedQuotes = [];

	// for (const key in data) {
	// 	const quoteObj = {
	// 		id: key,
	// 		...data[key],
	// 	};

	// 	transformedQuotes.push(quoteObj);
	// }

	return data;
}

export async function getContent(pageData) {
	const response = await fetch(`${API_BASE_URL}/content/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the page content.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
// console.log('PAGE DATA VIA PHP');
// console.log(data);
// console.log('PAGE DATA VIA PHP (CONTENT DECODED)');
// console.log(JSON.parse(data['data']['content']));
	return data;
}

export async function getStrapiContent(pageData) {
	console.log('PAGEDATA:');
	console.log(pageData);
	console.log(pageData.url);

	const response = await fetch(`${STRAPI_BASE_URL}/${pageData.url}`);

	// Check for unsuccessful response
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the page content.');
	}

	const data = await response.json();

	console.log('DATA FROM STRAPI');
	console.log(data);
	return data;
}
