import { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/auth-context';
import NotificationContext from '../context/notification-context';
import useHttp from '../hooks/use-http';
import { getEmployeeName } from '../api/employee';
import NavigationCallouts from '../components/containers/home/NavigationCallouts';
import PeopleOut from '../components/containers/home/PeopleOut';
import RemainingTime from '../components/containers/home/RemainingTime';
import LatestResources from '../components/containers/home/LatestResources';
// import SignIns from '../components/containers/home/SignIns';

const Home = () => {
	// Set header information and body classes
	document.title = "Time Off Tracker | Powered by Media Logic";
	document.body.className = "home";

	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);

	const [employeeInfo, setEmployeeInfo] = useState([]);

	const requestData = {
		token: authCtx.token
	};

	const { sendRequest, status, data, error } = useHttp(getEmployeeName, true);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					error
				)
			} else if (!data['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					data['status_code'] + ': ' + data['status']
				)
			} else {
				// console.log('EMPLOYEE DATA');
				// console.log(data['data']);

				setEmployeeInfo(data['data']);
			}

			// Reset timeout
			authCtx.updateExpiration();
		}
	}, [status, error, data]);

	useEffect(() => {
		sendRequest(requestData);
	}, [sendRequest]);

	return (
		<>
			<header role="banner">
				<div className="constrained">
					<h1>&#x1F44B; {employeeInfo.firstName}</h1>
				</div>
			</header>

			<main>
				<div className="constrained">
					<div className="outer-flexwrap">
						<RemainingTime />
						<PeopleOut />
						<NavigationCallouts />

						{/*
							<div className="column group-column">
								<RemainingTime />
								<LatestResources />
							</div>
						*/}
					</div>
				</div>
			</main>
		</>
	)
}

export default Home;