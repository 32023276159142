/**
***************************************************************************
* NAME 			: LinkCard
* PROPS	 		: children (label)
*		 		: to (link)
*		 		: img (src)
*		 		: width (optional - default 40)
*		 		: height (optional - default 40)
* RETURNS 		: Styled link
* LAST MODIFIED 	: June 17, 2021
***************************************************************************
*/

import { Link } from 'react-router-dom';
// import classes from './LinkCard.module.css';

const LinkCard = (props) => {
	const imgsrc = '/img/' + props.img;

	return (
		// <Link to={props.to} className={classes.link-card}>
		<Link to={props.to} className="link-card">
			<div>
				<p>{props.children}</p>
				<img src={imgsrc} alt={props.children} width={props.width || '40'} height={props.height || '40'} />
			</div>
		</Link>
	)
}

export default LinkCard;