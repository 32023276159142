import Navigation from '../components/containers/header/Navigation';
import Topper from '../components/containers/header/Topper';

const Header = () => {
	return (
		<>
			<Navigation />
			<Topper />
		</>
	)
}

export default Header;