/**
***************************************************************************
* NAME 			: CalendarGrid
* PROPS	 		: data (array - getCalendar data)
*				: type (array) (makes days active for selection)
*				: - timeOffTypeId
*				: - name
*				: - class
*				: employee (optional) (used for HR to view employee's time)
*				: - employeeId
*				: - firstName
*				: - lastName
*				: - name
*				: onSelectDay (selectDayHandler) (optional)
*				: isLoading (calendarStatus === 'pending')
* RETURNS 		: The populated grid portion of the calendar
* LAST MODIFIED 	: March 24, 2022
***************************************************************************
*/

import { useState, useEffect, useContext, useRef } from 'react';
import AuthContext from '../../../context/auth-context';
import NotificationContext from '../../../context/notification-context';
import useHttp from '../../../hooks/use-http';
import { getCalendarGridSummary } from '../../../api/time-off-tracker';
import LoadingSpinner from '../../UI/LoadingSpinner';
import CalendarGridSelected from './CalendarGridSelected';
// import { Link } from 'react-router-dom';

const CalendarGrid = (props) => {
	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);

	const [calendarData, setCalendarData] = useState([]);
	const [selectedDays, setSelectedDays] = useState([]);
	const [gridSummary, setGridSummary] = useState([]);
	const [isHR, setIsHR] = useState(false);
	
	const [checkedState, setCheckedState] = useState(
		new Array(42).fill(false)
	);
	
	let type = [];
	let typeName = "";
	let selectable = false;
	if (typeof props.type !== 'undefined') {
		if (typeof props.type.timeOffTypeId !== 'undefined') {
			type = props.type;
			typeName = props.type.name;
			// console.log('TYPE');
			// console.log(type);
			selectable = true;
		}
	}
	let initialExpand = "withhover";
	if (typeof props.onSelectDay !== 'undefined') {
		initialExpand = "";
	}

	let weekData = [
		{week: 1, startPos: 1, endPos: 7},
		{week: 2, startPos: 8, endPos: 14},
		{week: 3, startPos: 15, endPos: 21},
		{week: 4, startPos: 22, endPos: 28},
		{week: 5, startPos: 29, endPos: 35},
		{week: 6, startPos: 36, endPos: 42}
	];

	const { sendRequest, status, data, error } = useHttp(getCalendarGridSummary, true);

	/**
	**********
	DAY FUNCTIONS
	**********
	*/
	const previousWeekDay = (day) => {
		day = new Date(day);
		day = new Date(day.setDate(day.getDate() - 1));
		while (
			(day.getDay() == 5) ||
			(day.getDay() == 6)
		) {
			day = new Date(day.setDate(day.getDate() - 1));
		}

		day = day.toISOString().slice(0, 10);
		return day;
	}
	const nextWeekDay = (day) => {
		day = new Date(day);
		day = new Date(day.setDate(day.getDate() + 1));
		
		while (
			(day.getDay() == 5) ||
			(day.getDay() == 6)
		) {
			day = new Date(day.setDate(day.getDate() + 1));
		}

		day = day.toISOString().slice(0, 10);
		return day;
	}

	/**
	**********
	INIT
	**********
	*/
	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					error
				)
			} else if (!data['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					data['status_code'] + ': ' + data['status']
				)
			} else {
				// console.log('YEAR SUMMARIES');
				// console.log(data['data']);

				setGridSummary(data['data']);
			}
		}
	}, [status, error, data]);

	useEffect(() => {
		let requestData = {
			token: authCtx.token
		};

		if (typeof props.employee !== 'undefined') {
			if (typeof props.employee.employeeId !== 'undefined') {
				requestData = {
					token: authCtx.token,
					id: props.employee.employeeId
				};

				setIsHR(true);
			}
		}
		// console.log('REQUEST DATA');
		// console.log(requestData);
		sendRequest(requestData);
	}, [sendRequest]);

	useEffect(() => {
		setCalendarData(props.data);
	}, [props.data]);

	// console.log('CALENDARGRID');
	// console.log(props.data);

	/**
	**********
	HANDLERS
	**********
	*/
	const selectDayHandler = (value, position) => {
		let selectedDaysArray = [...selectedDays];
		let arrayLength = selectedDaysArray.length;
		let removed = false;
		
		const updatedCheckedState = checkedState.map((item, index) => index === position ? !item : item);
		setCheckedState(updatedCheckedState);
		
		// Handler is being called from the selection of a day on the calendar grid
		// not a call to refresh due to a change in the type (useEffect below)
		if (arrayLength > 0) {
			// Has the day already been added to the array?
			// If so, remove
			let index = selectedDaysArray.findIndex(d => d.date == value);
			if (index >= 0) {
				// Remove
				selectedDaysArray.splice(index, 1);
				removed = true;
			}
		}

		if (!removed) {
			// Add
			let cIndex = calendarData.findIndex(c => c.date == value);
			if (cIndex >= 0) {
				let tempObj = {};
				tempObj['date'] = value;
				tempObj['displayDate'] = calendarData[cIndex]['displayDate'];
				tempObj['dayOfWeek'] = calendarData[cIndex]['dayOfWeek'];

				let index = selectedDaysArray.findIndex(d => d.date > value);
				if (index >= 0) {
					selectedDaysArray.splice(index, 0, tempObj);
					selectedDays.splice(index, 0, tempObj);
				} else {
					selectedDaysArray.splice(arrayLength, 0, tempObj);
				}
			}
		}

		let displayButton = true;
		let message = "";
		// console.log('selectedDaysArray.length');
		// console.log(gridSummary['yearsummary']);
		
		if (selectedDaysArray.length > 0) {
			// Check to see if the requests are over the allotment
			let useYearArray = [];
			for (let i = 0; i < selectedDaysArray.length; i++) { // Loop through the requested days
				for (let j = 0; j < gridSummary['yearsummary'].length; j++) { // Loop through the allotted use years
					if ((selectedDaysArray[i]['date'] >= gridSummary['yearsummary'][j]['startDate']) && (selectedDaysArray[i]['date'] <= gridSummary['yearsummary'][j]['endDate'])) {
						useYearArray.push(gridSummary['yearsummary'][j]['useYear']);
						break;
					}
				}
			}

			let useyearRequests = 0;
			for (let k = 0; k < gridSummary['yearsummary'].length; k++) { // Loop through the allotted use years
				// Get the total for the use year
				useyearRequests = 0;
				for (let l = 0; l < useYearArray.length; l++) { // Loop through use year requests
					if (useYearArray[l] == gridSummary['yearsummary'][k]['useYear']) {
						useyearRequests++;
					}
				}

				if ((typeName == 'Vacation') && (useyearRequests > gridSummary['yearsummary'][k]['vacationRemaining'])) {
					displayButton = false;
					message = "More vacation days are being requested than available for the use year.";
					break;
				} else if ((typeName == 'Personal') && (useyearRequests > gridSummary['yearsummary'][k]['personalRemaining'])) {
					displayButton = false;
					message = "More personal days are being requested than available for the use year.";
					break;
				}
			}
			// /Check to see if the requests are over the allotment

			// Check to see if personal days are allowed
			if ((typeName == 'Personal') && (!isHR)) {
				let puos = []; // personal unavailable on selection
				// Build the unavailable days based on selections
				for (let i = 0; i < selectedDaysArray.length; i++) {
					puos.push(previousWeekDay(selectedDaysArray[i]['date']));
					puos.push(nextWeekDay(selectedDaysArray[i]['date']));
				}

				for (let i = 0; i < selectedDaysArray.length; i++) {
					// Check against holidays and personal days already requested
					// Check against unavailable days based on selections
					if (
						(gridSummary['personalunavailable'].find((unavailable) => unavailable === selectedDaysArray[i]['date'])) ||
						(puos.find((unavailable) => unavailable === selectedDaysArray[i]['date']))
					) {
						displayButton = false;
						message = "Personal time is not allowed for workdays prior to or following vacations, holidays, or other personal days. If you're looking to use consecutive personal days for sick time or religious holidays that are not part of the holiday schedule, please contact Melissa.";
					}
				}
			}
		} else {
			displayButton = false;
		}

		let selectDayData = {
			selectedDays: selectedDaysArray,
			displayButton,
			message
		};

		setSelectedDays([...selectedDaysArray]);

		if (typeof props.onSelectDay !== 'undefined') {
			props.onSelectDay(selectDayData);
		}

		// console.log('SELECTED DAYS LAST');
		// console.log(selectedDays);
	};

	useEffect(() => {
		selectDayHandler();
	}, [props.type]);

	return (
		<>
			{ (props.isLoading || (typeof calendarData === 'undefined') || (typeof gridSummary['yearsummary'] === 'undefined')) &&
				<div>
					<LoadingSpinner />
				</div>
			}
			{ !props.isLoading && (typeof calendarData !== 'undefined') && (typeof gridSummary['yearsummary'] !== 'undefined') &&
				<>
					<table>
						<tr>
							<th>su</th>
							<th>mo</th>
							<th>tu</th>
							<th>we</th>
							<th>th</th>
							<th>fr</th>
							<th>sa</th>
						</tr>
						{weekData.map(week =>
							<tr key={Math.random()}>
								{calendarData.map((info, index) =>
									<>
										{ (info.key >= week.startPos) && (info.key <= week.endPos) && 
											<td key={info.key} className={info.classAttr}>
												<div className="calendar-day">
													{ (selectable) && (info.selectable) &&
														<div>
															<input type="checkbox" value={info.date} id={"selectdaybutton" + info.key} className="selectday-button" onChange={() => selectDayHandler(info.date, index)} checked={checkedState[index]} />
															<label for={"selectdaybutton" + info.key}><span className="visuallyhidden">Click to select this day</span></label>
														</div>
													}
													<div className="datenumber"><p>{info.day}</p></div>
													<div className="employee-box">
														{(info.peopleOut.length > 0) &&
															info.peopleOut.map(person =>
																<p className="initials-box multiday"><abbr data-fullname={person.name} className={person.teamClass + ' ' + initialExpand} tabindex="0">{person.initials}</abbr><span className="visuallyhidden">{person.name}</span></p>
															)
														}
													</div>
													{(info.holiday != '') &&
														<div className="holidaytext">
															<p>{info.holiday}</p><p><strong>office closed</strong></p>
														</div>
													}
												</div>
											</td>
										}
									</>

								)}
							</tr>
						)}
					</table>

					{(typeof type.timeOffTypeId !== 'undefined') &&
						<CalendarGridSelected data={selectedDays} type={type} />
					}
				</>
			}
		</>
	)
}

export default CalendarGrid;
