import { useState, useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AuthContext from '../../../context/auth-context'
import NotificationContext from '../../../context/notification-context';
import LoadingSpinner from '../../UI/LoadingSpinner';
import useInput from '../../../hooks/use-input';
import useHttp from '../../../hooks/use-http';
import { updateTimeZone } from '../../../api/account';
import { getTimeZones } from '../../../api/employee';
// import TextField from '@material-ui/core/TextField';

const TimeZoneForm = (props) => {
	const params = useParams();

	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);
	const [timeZones, setTimeZones] = useState([]);
	const [dstValue, setDstValue] = useState(1);
	const [formProcessing, setFormProcessing] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const timeZoneRef = useRef();
	const dstRef = useRef();

	let isHR = false;
	if (typeof params.employeeId !== 'undefined') {
		if (!isNaN(params.employeeId) && (params.employeeId > 0)) {
			isHR = true;
		}
	}

	const { sendRequest, status, data, error } = useHttp(updateTimeZone);
	const { sendRequest: timeZoneRequest, status: timeZoneStatus, data: timeZoneData, error: timeZoneError } = useHttp(getTimeZones);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					error
				)
			} else if (!data['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					data['status_code'] + ': ' + data['status']
				)
			} else {
				if (data['status_code'] == 200) {
					// Password reset
					notifyCtx.displayNotification(
						"OK",
						'The time zone and DST settings have been updated'
					)

					// resetAddress();
					// resetCity();
					// resetZip();

					// Return to log in
					props.onUpdate(true);
				} else {
					setErrorMessage(data['status']);
				}
			}
		}
	}, [status, error, data]);

	/**
	**********
	INIT
	**********
	*/
	useEffect(() => {
		if (timeZoneStatus === 'completed') {
			if (timeZoneError !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					timeZoneError
				)
			} else if (!timeZoneData['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					timeZoneData['status_code'] + ': ' + timeZoneData['status']
				)
			} else {
				// console.log('timeZoneS');
				// console.log(timeZoneData['data']);

				setTimeZones(timeZoneData['data']);
			}
			// setIsLoading(false);
		}
	}, [timeZoneStatus, timeZoneError, timeZoneData]);

	useEffect(() => {
		const requestData = {
			token: authCtx.token
		};

		timeZoneRequest(requestData);
		setDstValue(props.data.useDST);
	}, []);

	/**
	**********
	HANDLERS
	**********
	*/
	const dstYesHandler = () => {
		setDstValue(1)
	}
	const dstNoHandler = () => {
		setDstValue(0)
	}

	const submitHandler = event => {
		event.preventDefault();

		setFormProcessing(true);

		let formData = {
			token: authCtx.token,
			timeZone: timeZoneRef.current.value,
			dst: dstValue
		};

		if (isHR) {
			formData = {
				token: authCtx.token,
				timeZone: timeZoneRef.current.value,
				dst: dstValue,
				employee: params.employeeId
			};
		}
		console.log('FORM DATA');
		console.log(formData);

		sendRequest(formData);

		setFormProcessing(false);
		// lnameRef.current.value = ''; // Not ideal but doable (because you're directly manipulating the DOM)
	}

	const cancelHandler = () => {
		props.onUpdate();
	}

	return (
		<div className="formwrap">
			{
				<p className="error-text">{errorMessage}</p>
			}
			<form onSubmit={submitHandler}>
				<div className="customselect">
					<label htmlFor='timeZone'>Time Zone</label>
					<select name="timeZone" id="timeZone" ref={timeZoneRef}>
						{timeZones.map(timeZone =>
							<> 
								{timeZone.timeZoneId != props.data.timeZoneId &&
									<option key={timeZone.timeZoneId} value={timeZone.timeZoneId}>{timeZone.name}</option>
								}
								{timeZone.timeZoneId == props.data.timeZoneId &&
									<option key={timeZone.timeZoneId} value={timeZone.timeZoneId} selected="selected">{timeZone.name}</option>
								}
							</>
						)}
					</select>
				</div>
				<div>
					<p>Observe DST</p>
					<div className="radio-wrapper">
						<input
							type="radio"
							name="dst"
							id="dstYes"
							value="1"
							checked={(dstValue) ? true : false}
							onClick={dstYesHandler}
						/>
						<label htmlFor='dstYes'>yes</label>
					</div>
					<div className="radio-wrapper">
						<input
							type="radio"
							name="dst"
							id="dstNo"
							value="0"
							checked={(!dstValue) ? true : false}
							onClick={dstNoHandler}
						/>
						<label htmlFor='dstNo'>no</label>
					</div>
				</div>
				<div>
					<p>You'll have to log out and log back in after the change to see the time zone modification throughout the MLAnnex</p>
				</div>
				<div className="button-wrap">
					{ formProcessing &&
						<div>
							<LoadingSpinner />
						</div>
					}
					{ !formProcessing &&
						<>
							<button type="button" className="secondary" onClick={cancelHandler} disabled={formProcessing}>cancel</button>
							<button type="submit" disabled={formProcessing}>update</button>
						</>
					}
				</div>
			</form>
		</div>
	)
}

export default TimeZoneForm;