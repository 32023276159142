/**
***************************************************************************
* NAME 			: EmployeeCard
* PROPS	 		: children (employee name)
*				: id (employeeId)
*				: firstName (of employee)
*				: lastName (of employee)
*		 		: url (directory of resulting page when clicked)
*		 		: width (optional - default 117)
*		 		: height (optional - default 85)
* RETURNS 		: Styled link to direct to employee details
* LAST MODIFIED 	: February 23, 2022
***************************************************************************
*/

import { Link } from 'react-router-dom';
// import classes from './OrangeCard.module.css';

const EmployeeCard = (props) => {
//	const API_URL = "https://qxwuuqemnacfhweocuzr.mlinc.com";
	const name = props.firstName.toLowerCase() + ' ' + props.lastName.toLowerCase();
	const firstInitial = props.firstName.charAt(0);
	const imgsrc = '/img/headshots/' + firstInitial.toLowerCase() + props.lastName.toLowerCase() + '.jpg';
	const url = props.url + props.id;

	return (
		<Link to={url} className="emp-card">
			<div class="headshot">
				<img src={imgsrc} alt={name} width={props.width || '117'} height={props.height || '85'} />
			</div>
			<div class="info">
				<p class="name">{name}</p>
				
				<img src="/img/card-arrow-orange.svg" alt="" width="36" height="36" className="arrow" />
			</div>
		</Link>
	)
}

export default EmployeeCard;