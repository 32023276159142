/**
***************************************************************************
* NAME 			: Modal
* PROPS	 		: data (array)
*				: - title
*				: - message
*				: - additionalMessage (optional)
*				: - buttonLabel (optional)
*				: onConfirm
*				: onApprove (optional)
*				: onCancel (optional)
*				: formProcessing
* RETURNS 		: Displays Model window 
* LAST MODIFIED 	: May 24, 2022
***************************************************************************
*/

import ModelCard from './ModalCard';
// import Button from './Button';
import classes from './Modal.module.css';

const Modal = (props) => {
	let buttonLabel = "yes";
	if (typeof props.data.buttonLabel !== 'undefined')  {
		if (props.data.buttonLabel != "") {
			buttonLabel = props.data.buttonLabel;
		}
	}

	return (
		<div>
			<div className={classes.backdrop} />

			<ModelCard className={classes.modal}>
				<header className={classes.header}>
					<h2>{props.data.title}</h2>
				</header>
				<div className={classes.content}>
					<p>{props.data.message}</p>
					{ (typeof props.data.additionalMessage !== 'undefined') &&
						<p>{props.data.additionalMessage}</p>
					}
				</div>
				<footer className={classes.actions}>
					<div className={classes.button_wrap}>
						{ (typeof props.onCancel !== 'undefined') &&
							<button type="button" onClick={props.onCancel} className="secondary" disabled={props.formProcessing}>cancel</button>
						}
						
						<button type="button" onClick={props.onConfirm} disabled={props.formProcessing}>{buttonLabel}</button>
						
						{ (typeof props.onApprove !== 'undefined') &&
							<button type="button" onClick={props.onApprove} disabled={props.formProcessing}>approve</button>
						}
					</div>
				</footer>
			</ModelCard>
		</div>
	);
};

export default Modal;