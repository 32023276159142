import { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AuthContext from '../../../context/auth-context'
import NotificationContext from '../../../context/notification-context';
import LoadingSpinner from '../../UI/LoadingSpinner';
import useInput from '../../../hooks/use-input';
import useHttp from '../../../hooks/use-http';
import { updatePersonalEmail } from '../../../api/account';
// import TextField from '@material-ui/core/TextField';

const PersonalEmailForm = (props) => {
	const params = useParams();

	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);
	const [formProcessing, setFormProcessing] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	let isHR = false;
	if (typeof params.employeeId !== 'undefined') {
		if (!isNaN(params.employeeId) && (params.employeeId > 0)) {
			isHR = true;
		}
	}

	const { sendRequest, status, data, error } = useHttp(updatePersonalEmail);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					error
				)
			} else if (!data['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					data['status_code'] + ': ' + data['status']
				)
			} else {
				if (data['status_code'] == 200) {
					// Password reset
					notifyCtx.displayNotification(
						"OK",
						'The email address has been updated'
					)

					// Return
					props.onUpdate(true);
				} else {
					setErrorMessage(data['status']);
				}
			}
		}
	}, [status, error, data]);

	useEffect(() => {
		emailDefault(props.data.personalEmailAddress);
	}, []);

	// Requirement types
	const isEmail = value => (value == null) || (value.length == 0) || (value.includes('@') && value.includes('.'));

	const {
		value: enteredEmail,
		isValid: emailIsValid,
		hasError: emailHasError,
		valueChangeHandler: emailChangedHandler,
		inputBlurHandler: emailBlurHandler,
		valueSubmitHandler: emailSubmitHandler,
		defaultValue: emailDefault,
		reset: resetEmail
	} = useInput(isEmail); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	let formIsValid = false;
	if (emailIsValid) { // And any other form fields
		formIsValid = true;
	}

	const submitHandler = event => {
		event.preventDefault();

		setFormProcessing(true);

		// Added (if form is able to be submitted first before error checking)
		emailSubmitHandler();

		if (!formIsValid) {
			setErrorMessage(null);
			setFormProcessing(false);
			return;
		}

		let formData = {
			token: authCtx.token,
			email: enteredEmail
		};

		if (isHR) {
			formData = {
				token: authCtx.token,
				email: enteredEmail,
				employee: params.employeeId
			};
		}
		// console.log('FORM DATA');
		// console.log(formData);

		sendRequest(formData);

		setFormProcessing(false);
		// lnameRef.current.value = ''; // Not ideal but doable (because you're directly manipulating the DOM)
	}

	const cancelHandler = () => {
		props.onUpdate();
	}

	const emailInputClasses = emailHasError ? 'form-control invalid' : 'form-control';

	return (
		<div className="formwrap">
			{
				<p className="error-text">{errorMessage}</p>
			}
			<form onSubmit={submitHandler}>
				<div className={emailInputClasses}>
					<label htmlFor='email'>personal email address</label>
					<input
						type="email"
						name="email"
						id="email"
						maxLength="50"
						onChange={emailChangedHandler}
						onBlur={emailBlurHandler}
						value={enteredEmail}
					/>
					{emailHasError &&
						<p className="error-text">Enter a valid email address</p>
					}
				</div>
				<div className="button-wrap">
					{ formProcessing &&
						<div>
							<LoadingSpinner />
						</div>
					}
					{ !formProcessing &&
						<>
							<button type="button" className="secondary" onClick={cancelHandler} disabled={formProcessing}>cancel</button>
							<button type="submit" disabled={formProcessing}>update</button>
						</>
					}
				</div>
			</form>
		</div>
	)
}

export default PersonalEmailForm;