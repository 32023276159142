import { useState, useContext, useEffect } from 'react';
import AuthContext from '../context/auth-context'
import useHttp from '../hooks/use-http';
import LoginForm from '../components/containers/home/LoginForm';
import BrowserForm from '../components/containers/home/BrowserForm';
import ForgotPasswordForm from '../components/containers/home/ForgotPasswordForm';
import PersonalPinForm from '../components/containers/home/PersonalPinForm';
import PeopleOut from '../components/containers/home/PeopleOut';
import { checkRememberMe } from '../api/authentication';
import LoadingSpinner from '../components/UI/LoadingSpinner';

const Login = () => {
	// Set header information and body classes
	document.title = "Time Off Tracker | Powered by Media Logic";
	document.body.className = "login";

	const [initBrowserMessage, setInitBrowserMessage] = useState(null);
	const [loginMessage, setLoginMessage] = useState(null);
	const [forgotPassword, setForgotPassword] = useState(false);
	const [personalPin, setPersonalPin] = useState(false);
	const [firstName, setFirstName] = useState('');
	const [isLoading, setIsLoading] = useState(true);

	const authCtx = useContext(AuthContext);

	const { sendRequest, status, data, error } = useHttp(checkRememberMe);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				// setErrorMessage(error);
			} else if (!data['success']) {
				// setErrorMessage(data['status_code'] + ': ' + data['status']);
			// } else if (!data['data']['message']) {
				// setErrorMessage(data['data']['message']);
			} else {
				// console.log('REMEMBER ME');
				// console.log(data['data']);

				if (data['data']['rememberMe']) {
					// console.log('YES');
					setPersonalPin(true);
					setFirstName(data['data']['firstName']);
				}
			}

			setIsLoading(false);
		}
	}, [status, error, data]);

	useEffect(() => {
		sendRequest();
	}, [sendRequest]);

	const initDataHandler = (message) => {
		// Send the data back to the parent component (now context)
		setInitBrowserMessage(message);
	}

	const loginMessageHandler = (message) => {
		// Send the message to log in
		setLoginMessage(message);
	}

	const passwordHandler = (ind) => {
		setLoginMessage('The password has been reset and sent to the email address provided');
		setForgotPassword(ind);
	}

	const personalPinHandler = (attempts) => {
		setPersonalPin((personalPin) ? false : true);
		if (attempts >= 3) {
			setFirstName('');
		}
	}

	return (
		<main>
			<div className="constrained">
				<div className="outer-flexwrap">
					<div className="column">
						{isLoading && <LoadingSpinner />}
						{!isLoading &&
							<>
								{!authCtx.isLoggedIn && !personalPin && !forgotPassword && <LoginForm onLogin={initDataHandler} onForgot={passwordHandler} onPin={personalPinHandler} pinAvailable={(!!firstName)} loginMessage={loginMessage} />}
								{!authCtx.isLoggedIn && personalPin && !forgotPassword && <PersonalPinForm firstName={firstName} onPassword={personalPinHandler} onMaxAttempts={loginMessageHandler} />}
								{!authCtx.isLoggedIn && forgotPassword && <ForgotPasswordForm onRequest={passwordHandler} />}
								{authCtx.isLoggedIn && !authCtx.trustedBrowser && <BrowserForm initMessage={initBrowserMessage} />}
							</>
						}
					</div>
					<div className="column">
						<PeopleOut />
					</div>

					{/*
						<aside>
						<ul>
							<li>
								<a href="https://mlcentral.workamajig.com/platinum/" target="_blank" rel="noreferrer" className="link-card">
									<div>
										<p>MLCentral</p>
										<img src="/img/workamajig-icn.svg" alt="" width="40" height="40" />
									</div>
								</a>
							</li>
							<li>
								<a href="https://www.mlcontenthub.com/" target="_blank" rel="noreferrer" className="link-card">
									<div>
										<p>MLContentHub</p>
										<img src="/img/mlcontenthub-icn.svg" alt="" width="40" height="40" />
									</div>
								</a>
							</li>
							<li>
								<a href="./" className="link-card">
									<div>
										<p>DataSnap</p>
										<img src="/img/datasnap-icn.svg" alt="" width="40" height="40" />
									</div>
								</a>
							</li>
							<li>
								<a href="./" className="link-card">
									<div>
										<p>RomiCheck</p>
										<img src="/img/romicheck-icn.svg" alt="" width="40" height="40" />
									</div>
								</a>
							</li>					
							<li>
								<a href="https://app.reviewsnap.com/login?locale=reviewsnap_EN" target="_blank" rel="noreferrer" className="link-card">
									<div>
										<p>ReviewSnap</p>
										<img src="/img/reviewsnap-icn.svg" alt="" width="40" height="40" />
									</div>
								</a>
							</li>
							<li>
								<a href="https://outlook.office.com/" target="_blank" rel="noreferrer" className="link-card">
									<div>
										<p>Webmail</p>
										<img src="/img/webmail-icn.svg" alt="" width="40" height="40" />
									</div>
								</a>
							</li>
							
						</ul>
						</aside>
					*/}
				</div>
			</div>
		</main>
	)
}

export default Login;