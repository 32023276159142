/**
***************************************************************************
* NAME 			: AtAGlance
* PROPS	 		: id (employeeId) (optional)
*		 		: showHeading (boolean) (optional)
* RETURNS 		: Groups the year and detail summaries for
*				: At A Glace and Direct Report sections
* LAST MODIFIED 	: March 24, 2022
***************************************************************************
*/

import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../../context/auth-context';
import NotificationContext from '../../../context/notification-context';
import useHttp from '../../../hooks/use-http';
import { getYearSummary, getYearDetail } from '../../../api/time-off-tracker';
import { getSupervisor } from '../../../api/employee';
import YearSummary from './YearSummary';
import YearDetails from './YearDetails';
import LinkCard from '../../UI/LinkCard';

const AtAGlance = (props) => {
	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);

	// If "id" was passed, data is requested for a direct report
	let employeeId = 0;
	if (typeof props.id !== 'undefined')  {
		if ((!isNaN(props.id)) && (props.id > 0)) {
			employeeId = props.id;
		}
	}

	// If "showHeading" was passed, check to see if the header is requested to be shown
	let showHeading = true;
	if (typeof props.showHeading !== 'undefined')  {
		if (props.showHeading === false) {
			showHeading = false;
		}
	}

	const heading = (employeeId != 0) ? "direct reports" : "at a glance";
	const showDisclaimer = (employeeId != 0) ? false : true;

	const [summary, setSummary] = useState(null);
	const [details, setDetails] = useState(null);
	const [supervisor, setSupervisor] = useState(null);
	const [summaryErrorMessage, setSummaryErrorMessage] = useState(null); // Not currently used for display
	const [detailErrorMessage, setDetailErrorMessage] = useState(null); // Not used for display but used as a condition

	const { sendRequest: summaryRequest, status: summaryStatus, data: summaryData, error: summaryError } = useHttp(getYearSummary, true);
	const { sendRequest: detailRequest, status: detailStatus, data: detailData, error: detailError } = useHttp(getYearDetail, true);
	const { sendRequest: supervisorRequest, status: supervisorStatus, data: supervisorData, error: supervisorError } = useHttp(getSupervisor, true);

	/**
	**********
	YEAR SUMMARY
	**********
	*/
	useEffect(() => {
		if (summaryStatus === 'completed') {
			if (summaryError !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					summaryError
				)
				setSummaryErrorMessage(summaryError);
			} else if (!summaryData['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					summaryData['status_code'] + ': ' + summaryData['status']
				)
				setSummaryErrorMessage(summaryData['status_code'] + ': ' + summaryData['status']);
			} else {
				// console.log('SUMMARY DATA');
				// console.log(summaryData);
				if (summaryData['status_code'] != 200) {
					setSummaryErrorMessage(summaryData['status'])
				} else {
					setSummary(summaryData['data']);
				}
				// setSummary(summaryData['data']);
			}
		}
	}, [summaryStatus, summaryError, summaryData]);

	/**
	**********
	YEAR DETAILS
	**********
	*/
	useEffect(() => {
		if (detailStatus === 'completed') {
			if (detailError !== null) {
				setDetailErrorMessage(detailError);
			} else if (!detailData['success']) {
				setDetailErrorMessage(detailData['status_code'] + ': ' + detailData['status']);
			} else {
				// console.log('DETAIL DATA');
				// console.log(detailData['data']);
				setDetails(detailData['data']);
			}
		}
	}, [detailStatus, detailError, detailData]);

	/**
	**********
	SUPERVISOR
	**********
	*/
	useEffect(() => {
		if (supervisorStatus === 'completed') {
			if (supervisorError !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					supervisorError
				)
			} else if (!supervisorData['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					supervisorData['status_code'] + ': ' + supervisorData['status']
				)
			} else {
				// console.log('SUPERVISOR DATA');
				// console.log(supervisorData['data']);

				setSupervisor(supervisorData['data']['supervisor']);
			}
		}
	}, [supervisorStatus, supervisorError, supervisorData]);

	/**
	**********
	INIT
	**********
	*/
	useEffect(() => {
		let requestData = [];
		if (employeeId != 0) {
			requestData = {
				token: authCtx.token,
				id: employeeId
			};
		} else {
			requestData = {
				token: authCtx.token
			};
		}
		summaryRequest(requestData);
		detailRequest(requestData);
		supervisorRequest(requestData);
	}, [summaryRequest, detailRequest, supervisorRequest]);

	/**
	**********
	HANDLERS
	**********
	*/
	const changeYearHandler = (yearData) => {
		let requestData = [];
		if (employeeId != 0) {
			requestData = {
				token: authCtx.token,
				useYear: yearData.useYear,
				id: employeeId
			};
		} else {
			requestData = {
				token: authCtx.token,
				useYear: yearData.useYear
			};
		}
		summaryRequest(requestData);
		detailRequest(requestData);
	};

	return (
		<>
		{ (showHeading) && (summary !== null) && (
			<h2>{ heading }</h2>
		)}
		<div>
			<div className="flex-wrap">
				<div class="pageitem atglance-default">
					{ (summary !== null) &&
						<YearSummary data={summary} isLoading={summaryStatus === 'pending'} onChangeYear={changeYearHandler} />
					}
					{ detailErrorMessage == null &&  
						<YearDetails data={details} isLoading={detailStatus === 'pending'} showDisclaimer={showDisclaimer} />
					}
				</div>
			</div>

			{ (employeeId == 0) && (supervisor) &&
				<LinkCard to="request-time-off/" img="icn-tot-white.svg">request time off</LinkCard>
			}
		</div>
		</>
	)
}

export default AtAGlance;