/**
***************************************************************************
* NAME 			: YearDetails
* PROPS	 		: data (array - getYearDetail data)
*				: showDisclaimer (optional)
*				: isLoading (summaryStatus === 'pending')
*				: onRemoveTime (removeTimeHandler) (optional)
* RETURNS 		: Listing of the details of personal time for the use year
* LAST MODIFIED 	: March 24, 2022
***************************************************************************
*/

// import DOMPurify from 'dompurify';
import { useState, useEffect, useContext } from 'react';
import AuthContext from '../../../context/auth-context';
import NotificationContext from '../../../context/notification-context';
import useHttp from '../../../hooks/use-http';
import { removeTimeOff } from '../../../api/time-off-tracker';
import LoadingSpinner from '../../UI/LoadingSpinner';
import Modal from '../../UI/Modal';

const YearDetails = (props) => {
	// console.log('YEAR DETAIL PROPS');
	// console.log(props);

	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);
	const [accordionClass, setAccordionClass] = useState('accordionitem_button');
	const [ariaExpanded, setAriaExpanded] = useState('false');

	const [approveConfirmation, setApproveConfirmation] = useState();

	let showDetails = false;
	let showDisclaimer = false;

	if (props.data !== null) {
		if (typeof props.data.length !== undefined) {
			if (props.data.length > 0) {
				showDetails = true;

				// Show the disclaimer if there is a scheduled (upcoming) date
				props.data.map(info => {
					if (info.status == 'scheduled') {
						showDisclaimer = true;
					}
				})
			}
		}
	}

	const { sendRequest, status, data, error } = useHttp(removeTimeOff, true);

	/**
	**********
	APPROVE
	**********
	*/
	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					error
				)
			} else if (!data['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					data['status_code'] + ': ' + data['status']
				)
			} else {
				notifyCtx.displayNotification(
					"OK",
					"Time off removed"
				)
				props.onRemoveTime();
			}
		}
	}, [status, error, data]);

	const approveConfirmationHandler = (event) => {
		setApproveConfirmation({
			id: event.target.value,
			title: 'remove time off',
			message: "Are you sure you'd like to remove the time off?",
			buttonLabel: "remove"
		});
	};

	const approveHandler = () => {
		const requestData = {
			token: authCtx.token,
			id: approveConfirmation['id']
		};

		setApproveConfirmation(null);
		sendRequest(requestData);
	};

	/**
	**********
	CANCEL
	**********
	*/
	const cancelHandler = () => {
		setApproveConfirmation(null);
	};

	/**
	**********
	ACCORDION
	**********
	*/
	const accordionHandler = () => {
		if (accordionClass != 'accordionitem_button open') {
			setAccordionClass('accordionitem_button open');
			setAriaExpanded('true');
		} else {
			setAccordionClass('accordionitem_button');
			setAriaExpanded('false');
		}
	};

	return (
		<>
			{ props.isLoading &&
				<div>
					<LoadingSpinner />
				</div>
			}
			{ approveConfirmation &&
				<div>
					<Modal data={approveConfirmation} onConfirm={approveHandler} onCancel={cancelHandler} />
				</div>
			}
			{ !props.isLoading && (showDetails) &&
				<div class="accordion">
					<div class="accordionitem">
						<button type="button" aria-controls="accordionitem_totyeardetails" aria-expanded={ariaExpanded} class={accordionClass} onClick={accordionHandler}>
							details
							<span class="arrow">
								<svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
									<path d="M1 2.54L2.7 1l8.3 9.32 8.31-9.22L21 2.62l-8.9 9.85a1.51 1.51 0 01-2.14.11l-.13-.13z" fill="#4f4f4f" class="accordionarrow" fill-rule="evenodd" />
									<path fill="none" d="M0 0h22v14H0z" />
								</svg>
							</span>
						</button>
						<div id="accordionitem_totyeardetails" class="accordionitem_details">
							<div class="flexwrap">
								{
									props.data.map(info =>
										<div key={info.timeOffId}>
											<div className={'info-item tot ' + info.typeAttr + ' ' + info.classAttr}>
												<p className="time-type">{info.type}</p>
												<p className="title">
													<time datetime={info.timeAttr}>{info.day}</time>
												</p>
												<div className="info"><p>{info.status}</p></div>
											</div>
											{ (typeof props.onRemoveTime !== 'undefined') && (info.removeInd) &&
												<div className="button-wrap remove">
													<button type="button" className="remove" id="remove" onClick={approveConfirmationHandler} value={info.timeOffId}>remove</button>
												</div>
											}
										</div>
									)
								}
							</div>
						
							{ (typeof props.showDisclaimer !== undefined) && (
								props.showDisclaimer && showDisclaimer &&
									<p className="instructions">If your plans have changed and would like to request a scheduled day-off be removed, please <a href="mailto:mjurgelon@medialogic.com?subject=Please remove scheduled time off">contact Melissa</a>.</p>
							)}
						</div>
					</div> {/* end accordionitem */}

				</div>	
			}
		</>
	)
}

export default YearDetails;