import { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AuthContext from '../../../context/auth-context'
import NotificationContext from '../../../context/notification-context';
import LoadingSpinner from '../../UI/LoadingSpinner';
import useInput from '../../../hooks/use-input';
import useHttp from '../../../hooks/use-http';
import { updatePhone } from '../../../api/account';

const PhoneForm = (props) => {
	const params = useParams();

	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);
	const [formProcessing, setFormProcessing] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	let isHR = false;
	if (typeof params.employeeId !== 'undefined') {
		if (!isNaN(params.employeeId) && (params.employeeId > 0)) {
			isHR = true;
		}
	}

	const { sendRequest, status, data, error } = useHttp(updatePhone);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					error
				)
			} else if (!data['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					data['status_code'] + ': ' + data['status']
				)
			} else {
				if (data['status_code'] == 200) {
					// console.log('PHONE');
					// console.log(data['data']);

					// Password reset
					notifyCtx.displayNotification(
						"OK",
						'The phone numbers have been updated'
					)

					// resetMobile();
					// resetHome();
					// resetWork();

					// Return to log in
					props.onUpdate(true);
				} else {
					setErrorMessage(data['status']);
				}
			}
		}
	}, [status, error, data]);

	/**
	**********
	INIT
	**********
	*/
	useEffect(() => {
		// Format
		let mobile = '';
		let home = '';
		let work= '';
		if ((props.data.mobilePhone != null) && (props.data.mobilePhone != '')) {
			mobile = props.data.mobilePhone.substring(0, 3) + '-' + props.data.mobilePhone.substring(3, 6) + '-' + props.data.mobilePhone.substring(6);
		}
		if ((props.data.homePhone != null) && (props.data.homePhone != '')) {
			home = props.data.homePhone.substring(0, 3) + '-' + props.data.homePhone.substring(3, 6) + '-' + props.data.homePhone.substring(6);
		}
		if ((props.data.workPhone != null) && (props.data.workPhone != '')) {
			work = props.data.workPhone.substring(0, 3) + '-' + props.data.workPhone.substring(3, 6) + '-' + props.data.workPhone.substring(6);
		}
		mobileDefault(mobile);
		homeDefault(home);
		workDefault(work);
	}, []);

	// Requirement types
	const isPhone = value => (value == '') || ((!isNaN(value)) && (value > 1000000000) && (value < 9999999999)) || ((!isNaN(value.replace(/-/g, ""))) && (value.replace(/-/g, "") > 1000000000) && (value.replace(/-/g, "") < 9999999999));

	const {
		value: enteredMobile,
		isValid: mobileIsValid,
		hasError: mobileHasError,
		valueChangeHandler: mobileChangedHandler,
		inputBlurHandler: mobileBlurHandler,
		valueSubmitHandler: mobileSubmitHandler,
		defaultValue: mobileDefault,
		reset: resetMobile
	} = useInput(isPhone); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	const {
		value: enteredHome,
		isValid: homeIsValid,
		hasError: homeHasError,
		valueChangeHandler: homeChangedHandler,
		inputBlurHandler: homeBlurHandler,
		valueSubmitHandler: homeSubmitHandler,
		defaultValue: homeDefault,
		reset: resetHome
	} = useInput(isPhone); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	const {
		value: enteredWork,
		isValid: workIsValid,
		hasError: workHasError,
		valueChangeHandler: workChangedHandler,
		inputBlurHandler: workBlurHandler,
		valueSubmitHandler: workSubmitHandler,
		defaultValue: workDefault,
		reset: resetWork
	} = useInput(isPhone); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	let formIsValid = false;
	if (mobileIsValid && homeIsValid && workIsValid) { // And any other form fields
		formIsValid = true;
	}

	const submitHandler = event => {
		event.preventDefault();

		setFormProcessing(true);

		// Added (if form is able to be submitted first before error checking)
		mobileSubmitHandler();
		homeSubmitHandler();
		workSubmitHandler();

		if (!formIsValid) {
			setErrorMessage(null);
			setFormProcessing(false);
			return;
		}

		let formData = {
			token: authCtx.token,
			mobile: enteredMobile.replace(/-/g, ""),
			home: enteredHome.replace(/-/g, ""),
			work: enteredWork.replace(/-/g, "")
		};

		if (isHR) {
			formData = {
				token: authCtx.token,
				mobile: enteredMobile.replace(/-/g, ""),
				home: enteredHome.replace(/-/g, ""),
				work: enteredWork.replace(/-/g, ""),
				employee: params.employeeId
			};
		}
		// console.log('FORM DATA');
		// console.log(formData);

		sendRequest(formData);

		setFormProcessing(false);
		// lnameRef.current.value = ''; // Not ideal but doable (because you're directly manipulating the DOM)
	}

	const cancelHandler = () => {
		props.onUpdate();
	}

	const mobileInputClasses = mobileHasError ? 'form-control invalid' : 'form-control';
	const homeInputClasses = homeHasError ? 'form-control invalid' : 'form-control';
	let workInputClasses = 'visually-hidden';
	let workDisabled = true;
	if (isHR) {
		workInputClasses = workHasError ? 'form-control invalid' : 'form-control';
		workDisabled = false;
	}

	return (
		<div className="formwrap">
			{
				<p className="error-text">{errorMessage}</p>
			}
			<form onSubmit={submitHandler}>
				<div className={mobileInputClasses}>
					<label htmlFor='mobile'>mobile phone</label>
					<input
						type="text"
						name="mobile"
						id="mobile"
						maxLength="12"
						onChange={mobileChangedHandler}
						onBlur={mobileBlurHandler}
						value={enteredMobile}
					/>
					{mobileHasError &&
						<p className="error-text">Must be 10 digits with or without dashes</p>
					}
				</div>
				<div className={homeInputClasses}>
					<label htmlFor='home'>home phone</label>
					<input
						type="text"
						name="home"
						id="home"
						maxLength="12"
						onChange={homeChangedHandler}
						onBlur={homeBlurHandler}
						value={enteredHome}
					/>
					{homeHasError &&
						<p className="error-text">must be 10 digits with or without dashes</p>
					}
				</div>
				<div className={workInputClasses}>
					<label htmlFor='work'>work phone</label>
					<input
						type="text"
						name="work"
						id="work"
						maxLength="12"
						onChange={workChangedHandler}
						onBlur={workBlurHandler}
						value={enteredWork}
						disabled={workDisabled}
					/>
					{workHasError &&
						<p className="error-text">must be 10 digits with or without dashes</p>
					}
				</div>
				<div className="button-wrap">
					{ formProcessing &&
						<div>
							<LoadingSpinner />
						</div>
					}
					{ !formProcessing &&
						<>
							<button type="button" className="secondary" onClick={cancelHandler} disabled={formProcessing}>cancel</button>
							<button type="submit" disabled={formProcessing}>update</button>
						</>
					}
				</div>
			</form>
		</div>
	)
}

export default PhoneForm;