import { useState, useContext, useEffect } from 'react';
import AuthContext from '../../../context/auth-context'
import LoadingSpinner from '../../UI/LoadingSpinner';
import useInput from '../../../hooks/use-input';
import useHttp from '../../../hooks/use-http';
import { authenticateEmployee } from '../../../api/authentication';
// import TextField from '@material-ui/core/TextField';

const LoginForm = (props) => {
	const authCtx = useContext(AuthContext);
	const [formProcessing, setFormProcessing] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [loginMessage, setLoginMessage] = useState(props.loginMessage);

	const logoutTime = (1 * 60 * 60 * 1000); // 1 hour * 60 minutes * 60 seconds * 1000 milliseconds
	
	const { sendRequest, status, data, error } = useHttp(authenticateEmployee);

	useEffect(() => {
		if (status === 'completed') {
//			console.log(data);
			if (error !== null) {
				setErrorMessage(error);
			} else if (!data['success']) {
				setErrorMessage(data['status_code'] + ': ' + data['status']);
			} else if (!data['data']['access']) {
				setErrorMessage(data['data']['message']);
			} else {
				// Log In
				// console.log('LOG IN');
				// console.log(data['data']);

				const expiration = new Date(new Date().getTime() + logoutTime) // + 1 hour
				authCtx.login(data['data']['token'], expiration.toISOString());

				if (data['data']['browser']) {
					authCtx.trustBrowser(true);
				} else {
					props.onLogin(data['data']['message']);
				}
			}
		}
	}, [status, error, data, logoutTime, authCtx, props]);

	// Requirement types
	const isNotEmpty = value => value.trim() !== '';
	const isEmail = value => value.includes('@medialogic.com');

	const {
		value: enteredEmail,
		isValid: emailIsValid,
		hasError: emailHasError,
		valueChangeHandler: emailChangedHandler,
		inputBlurHandler: emailBlurHandler,
		valueSubmitHandler: emailSubmitHandler
		// reset: resetEmail
	} = useInput(isEmail); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	const {
		value: enteredPassword,
		isValid: passwordIsValid,
		hasError: passwordHasError,
		valueChangeHandler: passwordChangedHandler,
		inputBlurHandler: passwordBlurHandler,
		valueSubmitHandler: passwordSubmitHandler,
		reset: resetPassword
	} = useInput(isNotEmpty); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	let formIsValid = false;
	if (emailIsValid && passwordIsValid) { // And any other form fields
		formIsValid = true;
	}

	const submitHandler = event => {
		event.preventDefault();

		setFormProcessing(true);
		setLoginMessage(null);

		// Added (if form is able to be submitted first before error checking)
 		emailSubmitHandler();
		passwordSubmitHandler();

		if (!formIsValid) {
			setErrorMessage(null);
			setFormProcessing(false);
			return;
		}

		const formData = {
			email: enteredEmail,
			password: enteredPassword
			// name: lnameRef.current.value,
		};

		sendRequest(formData);

		// props.onAddTest(testEntry);

		// Reset values
		// resetEmail();
		resetPassword();
		
		setFormProcessing(false);
		// lnameRef.current.value = ''; // Not ideal but doable (because you're directly manipulating the DOM)
	}

	const forgotHandler = () => {
		props.onForgot(true);
	}

	const personalPinHandler = () => {
		props.onPin(0);
	}

	const emailInputClasses = emailHasError ? 'form-control invalid' : 'form-control';
	const passwordInputClasses = passwordHasError ? 'form-control invalid' : 'form-control';

	return (
		<div className="formwrap">
			{(loginMessage != null) &&
				<p className="instructions">{loginMessage}</p>
			}
			{
				<p className="error-text">{errorMessage}</p>
			}
			<form onSubmit={submitHandler}>
				<div className={emailInputClasses}>
					<label htmlFor='email'>email</label>
					<input
						type="email"
						name="email"
						id="email"
						onChange={emailChangedHandler}
						onBlur={emailBlurHandler}
						value={enteredEmail}
					/>
					{emailHasError &&
						// <p className="error-text">Email must include @.</p>
						<p className="error-text">Valid email address required.</p>
					}
				</div>
				<div className={passwordInputClasses}>
					<label htmlFor='pw'>password</label>
					<input
						type="password"
						name="password"
						id="pw"
						onChange={passwordChangedHandler}
						onBlur={passwordBlurHandler}
						value={enteredPassword}
					/>
					{passwordHasError &&
						<p className="error-text">Required.</p>
					}
				</div>

				<div className="button-wrap">
					<div>
						<p><a onClick={forgotHandler}>forgot password?</a></p>
						{props.pinAvailable &&
							<p><a onClick={personalPinHandler}>use PIN</a></p>
						}
					</div>

					{ formProcessing &&
						<div>
							<LoadingSpinner />
						</div>
					}
					{ !formProcessing &&
						<button type="submit" disabled={formProcessing}>log in</button>
					}
				</div>
			</form>
		</div>
	)
}

export default LoginForm;