/**
***************************************************************************
* NAME 			: CalendarGridSelected
* PROPS	 		: data
*				: - date (Y-m-d)
*				: - displayDate (M d, Y)
*				: - dayOfWeek (l)
*				: type (array)
*				: - timeOffTypeId
*				: - name
*				: - class
*				: isLoading (summaryStatus === 'pending')
*				: message
* RETURNS 		: Listing of the selections made via the calendar grid
* RELATED			: YearDetails
* LAST MODIFIED 	: May 18, 2022
***************************************************************************
*/

// import DOMPurify from 'dompurify';
import LoadingSpinner from '../../UI/LoadingSpinner';

const CalendarGridSelected = (props) => {
	let showDetails = false;
	let typeName = "";
	let typeClass = "";
	// console.log('SELECTED');
	// console.log(props.data);

	if (props.data !== null) {
		if (typeof props.data.length !== 'undefined') {
			if (props.data.length > 0) {
				showDetails = true;
			}
		}
	}
	if (props.type !== null) {
		if (typeof props.type.timeOffTypeId !== 'undefined') {
			typeName = props.type.name.toLowerCase();
			typeClass = props.type.class;
		}
	}
	// if (props.type == 'vacation') {
	// 	classAttr = 'taken';
	// }

	return (
		<>
			{ props.isLoading &&
				<div>
					<LoadingSpinner />
				</div>
			}
			{ !props.isLoading && (showDetails) &&
				<div className="selected-days">
					<h3>selected</h3>
					<div className="selected-days-wrap">
						<div class="flexwrap">
							{
								props.data.map(info =>
									<div key={info.date}>
										<div className={'info-item tot ' + typeClass}>
											<p className="time-type">{info.displayDate}</p>
											<p className="title">
												<time datetime={info.date}>{info.dayOfWeek}</time>
											</p>
											<div className="info"><p>{typeName}</p></div>
										</div>
									</div>
								)
							}
						</div>	
					</div>
				</div>
			}
		</>
	)
}

export default CalendarGridSelected;