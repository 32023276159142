import { useState } from 'react';

const useInput = (validateValue) => {
	const [enteredValue, setEnteredValue] = useState(''); // First name will use state (every key stoke)
	const [isTouched, setIsTouched] = useState(false);

	const valueIsValid = validateValue(enteredValue); // Function passed is now executed
	const hasError = !valueIsValid && isTouched;

	const valueChangeHandler = (event) => {
		setEnteredValue(event.target.value);
	}

	const inputBlurHandler = (event) => {
		// setIsTouched(true);
	}

	// Added (if form is able to be submitted first before error checking)
	const valueSubmitHandler = (event) => {
		setIsTouched(true);
	}
		
	const defaultValue = (value) => {
		setEnteredValue(value);
		setIsTouched(false);
	}

	const reset = () => {
		setEnteredValue('');
		setIsTouched(false);
	}

	return {
		value: enteredValue,
		isValid: valueIsValid,
		hasError,
		valueChangeHandler, // Exposing functions (making available) for calling "page"
		inputBlurHandler,
		valueSubmitHandler, // Added (if form is able to be submitted first before error checking)
		defaultValue,
		reset
	}
}

export default useInput;