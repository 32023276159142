import { useState, useContext, useEffect } from 'react';
import AuthContext from '../../../context/auth-context'
import NotificationContext from '../../../context/notification-context';
import LoadingSpinner from '../../UI/LoadingSpinner';
import useInput from '../../../hooks/use-input';
import useHttp from '../../../hooks/use-http';
import { updatePassword } from '../../../api/account';
// import TextField from '@material-ui/core/TextField';

const PasswordForm = (props) => {
	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);
	const [formProcessing, setFormProcessing] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	const { sendRequest, status, data, error } = useHttp(updatePassword);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					error
				)
			} else if (!data['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					data['status_code'] + ': ' + data['status']
				)
			} else {
				if (data['status_code'] == 200) {
					// Password reset
					notifyCtx.displayNotification(
						"OK",
						'Your password has been modified'
					)

					// Reset form values and error message
					setErrorMessage(null);
					resetPassword();
					resetNewPassword();
					resetConfirmPassword();

					// Close (if HR)
					props.onUpdate();
				} else {
					setErrorMessage(data['status']);
				}
			}
		}
	}, [status, error, data]);

	// Requirement types
	const isPassword = value => value.length >= 8;
	const isNewPassword = value => value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/);
	const isConfirmPassword = value => value == enteredNewPassword;

	const {
		value: enteredPassword,
		isValid: passwordIsValid,
		hasError: passwordHasError,
		valueChangeHandler: passwordChangedHandler,
		inputBlurHandler: passwordBlurHandler,
		valueSubmitHandler: passwordSubmitHandler,
		reset: resetPassword
	} = useInput(isPassword); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	const {
		value: enteredNewPassword,
		isValid: newPasswordIsValid,
		hasError: newPasswordHasError,
		valueChangeHandler: newPasswordChangedHandler,
		inputBlurHandler: newPasswordBlurHandler,
		valueSubmitHandler: newPasswordSubmitHandler,
		reset: resetNewPassword
	} = useInput(isNewPassword); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	const {
		value: enteredConfirmPassword,
		isValid: confirmPasswordIsValid,
		hasError: confirmPasswordHasError,
		valueChangeHandler: confirmPasswordChangedHandler,
		inputBlurHandler: confirmPasswordBlurHandler,
		valueSubmitHandler: confirmPasswordSubmitHandler,
		reset: resetConfirmPassword
	} = useInput(isConfirmPassword); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	let formIsValid = false;
	if (passwordIsValid && newPasswordIsValid && confirmPasswordIsValid) { // And any other form fields
		formIsValid = true;
	}

	const submitHandler = event => {
		event.preventDefault();

		setFormProcessing(true);

		// Added (if form is able to be submitted first before error checking)
 		passwordSubmitHandler();
 		newPasswordSubmitHandler();
 		confirmPasswordSubmitHandler();

		if (!formIsValid) {
			setErrorMessage(null);
			setFormProcessing(false);
			return;
		}

		let formData = {
			token: authCtx.token,
			password: enteredPassword,
			newpassword: enteredNewPassword,
			confirmpassword: enteredConfirmPassword
		};
		// console.log('FORMDATA');
		// console.log(formData);

		sendRequest(formData);

		// Reset values
		// resetPassword();
		// resetNewPassword();
		// resetConfirmPassword();

		setFormProcessing(false);
		// lnameRef.current.value = ''; // Not ideal but doable (because you're directly manipulating the DOM)
	}

	const cancelHandler = () => {
		props.onUpdate();
	}

	const passwordInputClasses = passwordHasError ? 'form-control invalid' : 'form-control';
	const newPasswordInputClasses = newPasswordHasError ? 'form-control invalid' : 'form-control';
	const confirmPasswordInputClasses = confirmPasswordHasError ? 'form-control invalid' : 'form-control';

	return (
		<div className="formwrap">
			<p className="instructions">Your password will be changed for <strong>Time Off Tracker</strong>, <strong>MLCentral Annex</strong>, <strong>Client Portal</strong>, and <strong>ContentHub</strong></p>
			{
				<p className="error-text">{errorMessage}</p>
			}
			<form onSubmit={submitHandler}>
				<div className={passwordInputClasses}>
					<label htmlFor='password'>current password</label>
					<input
						type="password"
						name="password"
						id="password"
						onChange={passwordChangedHandler}
						onBlur={passwordBlurHandler}
						value={enteredPassword}
					/>
					{passwordHasError &&
						<p className="error-text">Valid password required.</p>
					}
				</div>
				<div className={newPasswordInputClasses}>
					<label htmlFor='newpassword'>new password</label>
					<input
						type="password"
						name="newpassword"
						id="newpassword"
						onChange={newPasswordChangedHandler}
						onBlur={newPasswordBlurHandler}
						value={enteredNewPassword}
					/>
					{newPasswordHasError &&
						// <p className="error-text">Email must include @.</p>
						<p className="error-text">The new password must be at least 8 characteres and contain an upper case letter, lower case letter, and number</p>
					}
				</div>
				<div className={confirmPasswordInputClasses}>
					<label htmlFor='confirmpassword'>confirm new password</label>
					<input
						type="password"
						name="confirmpassword"
						id="confirmpassword"
						onChange={confirmPasswordChangedHandler}
						onBlur={confirmPasswordBlurHandler}
						value={enteredConfirmPassword}
					/>
					{confirmPasswordHasError &&
						// <p className="error-text">Email must include @.</p>
						<p className="error-text">Confirm password must match the new password</p>
					}
				</div>
				<div className="button-wrap">
					{ formProcessing &&
						<div>
							<LoadingSpinner />
						</div>
					}
					{ !formProcessing &&
						<>
							<button type="button" className="secondary" onClick={cancelHandler} disabled={formProcessing}>cancel</button>
							<button type="submit" disabled={formProcessing}>update</button>
						</>
					}
				</div>
			</form>
		</div>
	)
}

export default PasswordForm;