import { useState, useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AuthContext from '../../../context/auth-context'
import NotificationContext from '../../../context/notification-context';
import LoadingSpinner from '../../UI/LoadingSpinner';
import useInput from '../../../hooks/use-input';
import useHttp from '../../../hooks/use-http';
import { updateBirthdayMessage } from '../../../api/account';
// import TextField from '@material-ui/core/TextField';

const BirthdayMessageForm = (props) => {
	const params = useParams();

	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);
	const [checkedInd, setCheckedInd] = useState('');
	const [formProcessing, setFormProcessing] = useState(false);

	let isHR = false;
	if (typeof params.employeeId !== 'undefined') {
		if (!isNaN(params.employeeId) && (params.employeeId > 0)) {
			isHR = true;
		}
	}

	const { sendRequest, status, data, error } = useHttp(updateBirthdayMessage);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					error
				)
			} else if (!data['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					data['status_code'] + ': ' + data['status']
				)
			} else {
				notifyCtx.displayNotification(
					"OK",
					'Sending reminder messages for your birthday has been turned ' + data['data']
				)

				if (checkedInd) {
					setCheckedInd('');
				} else {
					setCheckedInd('checked');
				}

				// Refresh data
				props.onUpdate();
			}
		}
	}, [status, error, data]);

	useEffect(() => {
		if (props.data.birthdayMessageInd) {
			setCheckedInd('checked');
		}
	}, []);

	const submitHandler = event => {
		event.preventDefault();

		setFormProcessing(true);

		let formData = {
			token: authCtx.token
		};

		if (isHR) {
			formData = {
				token: authCtx.token,
				employee: params.employeeId
			};
		}

		sendRequest(formData);

		setFormProcessing(false);
	}

	const cancelHandler = () => {
		props.onUpdate(true);
	}

	return (
		<div className="formwrap">
			<form onSubmit={submitHandler}>
				{ formProcessing &&
					<div>
						<LoadingSpinner />
					</div>
				}
				{ !formProcessing &&
					<>
						<p>Send announcement of your birthday to co-workers</p>
						<div class="birthday-toggle toggle-item">							
							<input type="checkbox" id="birthdaymsg" name="birthdaymsg" checked={checkedInd} onClick={submitHandler} />
							<label for="birthdaymsg" aria-label="Toggle birthday announcements on or off" title="Toggle birthday announcements on or off">
								<span class="on">off</span>
								<span class="off">on</span>
							</label>
						</div>
						<div className="button-wrap">
							<button type="button" onClick={cancelHandler} disabled={formProcessing}>done</button>
						</div>
					{/*
						<button type="submit" disabled={formProcessing}>turn {(props.data.birthdayMessageInd) ? 'off' : 'on'}</button>
					*/}

					</>
				}
			</form>
		</div>
	)
}

export default BirthdayMessageForm;