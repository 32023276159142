/**
***************************************************************************
* NAME 			: YearSummary
* PROPS	 		: data (array - getYearSummary data)
*				: isLoading (summaryStatus === 'pending')
*				: onChangeYear (changeYearHandler)
* RETURNS 		: Display of the summary of personal time for the use year
* LAST MODIFIED 	: February 14, 2022
***************************************************************************
*/

import { useState, useEffect, useRef } from 'react';
// import { Link } from 'react-router-dom';
import LoadingSpinner from '../../UI/LoadingSpinner';

const YearSummary = (props) => {
	// console.log('YEAR PROPS');
	// console.log(props);

	let employeeName = "";
	let useYear = "";
	let startDate = "";
	let endDate = "";
	let daysRemaining = 1;
	let previousYear = 1;
	let previousDisabled = true;
	let nextYear = 3;
	let nextDisabled = true;
	let vacationAvailable = 0;
	let vacationTaken = 0;
	let vacationRequested = 0;
	let vacationScheduled = 0;
	let vacationPaid = 0;
	let vacationRollover = 0;
	let vacationRemaining = 0;
	let personalAvailable = 0;
	let personalTaken = 0;
	let personalRequested = 0;
	let personalScheduled = 0;
	let personalPaid = 0;
	let personalRollover = 0;
	let personalRemaining = 0;
	let personalInfo = 1;
	let heading = "";

	const prevuseyearRef = useRef('');
	const nextuseyearRef = useRef('');

	if (props.data !== null) {
		if (typeof props.data.useyear !== undefined) {
			employeeName = props.data.name;
			useYear = props.data.useyear;
			startDate = props.data.startdate;
			endDate = props.data.enddate;
			daysRemaining = props.data.daysremaining;
			previousYear = props.data.previousyear;
			nextYear = props.data.nextyear;
			vacationAvailable = props.data.vacationavailable;
			vacationTaken = props.data.vacationtaken;
			vacationRequested = props.data.vacationrequested;
			vacationScheduled = props.data.vacationscheduled;
			vacationPaid = props.data.vacationpaid;
			vacationRollover = props.data.vacationrollover;
			vacationRemaining = props.data.vacationremaining;
			personalAvailable = props.data.personalavailable;
			personalTaken = props.data.personaltaken;
			personalRequested = props.data.personalrequested;
			personalScheduled = props.data.personalscheduled;
			personalPaid = props.data.personalpaid;
			personalRollover = props.data.personalrollover;
			personalRemaining = props.data.personalremaining;
			personalInfo = props.data.personalinfo;

			heading = (!personalInfo) ? employeeName.toLowerCase() : 'year ' + useYear;
			if (props.data.previousyear > 0) {
				previousDisabled = false;
			}
			if (props.data.nextyear > 0) {
				nextDisabled = false;
			}
		}
	}

	function previousHandler(event) {
		event.preventDefault();

		const data = {
			useYear: prevuseyearRef.current.value,
		};

		props.onChangeYear(data);
	}

	function nextHandler(event) {
		event.preventDefault();

		const data = {
			useYear: nextuseyearRef.current.value,
		};

		props.onChangeYear(data);
	}

	return (
		<>
			<div class="select-year">
				
				<button type="button" class="prev" id="prevuseyear" onClick={previousHandler} value={previousYear} ref={prevuseyearRef} disabled={previousDisabled}>Show previous year</button>
				{/* <button type="button" class="prev" id="tot-prevyear" onClick={previousHandler} value={previousValue} ref={useYearRef}>Show previous year</button> */}
				
				<div class="year-info">
					<h3>{heading}</h3>
					<p>({startDate} - {endDate})</p>
				</div>
				
				<button type="button" class="next" id="nextuseyear" aria-disabled="true" aria-describedby="No more years to show" onClick={nextHandler} value={nextYear} ref={nextuseyearRef} disabled={nextDisabled}>Show next year</button>
				{/* toggle disabled and related aria attributes (in both directions)
				<button type="button" class="next" id="tot-nextyear" aria-disabled="true" aria-describedby="No more years to show" disabled={nextDisabled}>Show next year</button>  */}
			</div>
			
			<div class="flexwrap">
				<div class="timeoff">
					<h4>vacation - {vacationAvailable} days available</h4>
					<ul class="day-display vacation">
						<li class="day-display_box taken">
							{vacationTaken} <span>taken</span>
						</li>
						{ daysRemaining > 0 && 
							<>
								<li class="day-display_box requested">
									{vacationRequested} <span>requested</span>
								</li>
								<li class="day-display_box scheduled">
									{vacationScheduled} <span>scheduled</span>
								</li>
							</>
						}
						{ daysRemaining == 0 && 
							<>
								<li class="day-display_box requested">
									{vacationPaid} <span>paid</span>
								</li>
								<li class="day-display_box scheduled">
									{vacationRollover} <span>rollover</span>
								</li>
							</>
						}
						<li class="day-display_box remaining">
							{vacationRemaining} <span>remaining</span>
						</li>
					</ul>
				</div>
				
				<div class="timeoff">
					<h4>personal - {personalAvailable} days available</h4>
					<ul class="day-display personal">
						<li class="day-display_box taken">
							{personalTaken} <span>taken</span>
						</li>
						{ daysRemaining > 0 &&
							<>
								<li class="day-display_box requested">
									{personalRequested} <span>requested</span>
								</li>
								<li class="day-display_box scheduled">
									{personalScheduled} <span>scheduled</span>
								</li>
							</>
						}
						{ daysRemaining == 0 &&
							<>
								<li class="day-display_box requested">
									{personalPaid} <span>paid</span>
								</li>
								<li class="day-display_box scheduled">
									{personalRollover} <span>rollover</span>
								</li>
							</>
						}
						<li class="day-display_box remaining">
							{personalRemaining} <span>remaining</span>
						</li>
					</ul>
				</div>
			</div>
		</>
	)
}

export default YearSummary;