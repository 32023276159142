import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../context/auth-context';
import useHttp from '../../hooks/use-http';
import { getDirectReports, getRole } from '../../api/employee';
import AtAGlance from '../../components/containers/time-off-tracker/AtAGlance';
import PendingApprovals from '../../components/containers/time-off-tracker/PendingApprovals';
import Calendar from '../../components/containers/time-off-tracker/Calendar';
import LinkCard from '../../components/UI/LinkCard';

const TimeOffTracker = () => {
	// Set header information and body classes
	document.title = "Time Off | Time Off Tracker";
	document.body.className = "time-off-tracker";

	const authCtx = useContext(AuthContext);

	const [isSupervisor, setIsSupervisor] = useState(false);
	const [directReports, setDirectReports] = useState([]);
	const [pendingApprovals, setPendingApprovals] = useState(0);
	const [role, setRole] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState(null);

	const requestData = {
		token: authCtx.token
	};
	let currentApprovals = 0;

	const { sendRequest, status, data, error } = useHttp(getDirectReports, true);
	const { sendRequest: roleRequest, status: roleStatus, data: roleData, error: roleError } = useHttp(getRole, true);

	/**
	**********
	INIT
	**********
	*/
	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				setErrorMessage(error);
			} else if (!data['success']) {
				setErrorMessage(data['status_code'] + ': ' + data['status']);
			} else {
				// console.log('SUPERVISOR DATA');
				// console.log(data['data']);

				setIsSupervisor(data['data']['is_supervisor']);
				setDirectReports(data['data']['direct_reports']);
				setPendingApprovals(data['data']['pending_approvals']);
			}
			setIsLoading(false);

			// Reset timeout
			authCtx.updateExpiration();
		}
	}, [status, error, data]);

	useEffect(() => {
		sendRequest(requestData);
	}, [sendRequest]);

	/**
	**********
	ROLE
	**********
	*/
	useEffect(() => {
		if (roleStatus === 'completed') {
			if (roleError !== null) {
				setErrorMessage(roleError);
			} else if (!roleData['success']) {
				setErrorMessage(roleData['status_code'] + ': ' + roleData['status']);
			} else {
				// console.log('ROLE DATA');
				// console.log(roleData['data']);

				setRole(roleData['data']['role']);
			}
		}
	}, [roleStatus, roleError, roleData]);

	useEffect(() => {
		roleRequest(requestData);
	}, [roleRequest]);

	/**
	**********
	HANDLERS
	**********
	*/
	const approvalHandler = () => {
		currentApprovals = pendingApprovals - 1;
		setPendingApprovals(currentApprovals);
		sendRequest(requestData);
	};
	
	return (
		<>
			<header role="banner">
				<div className="constrained">
					<h1>time off</h1>
				</div>
			</header>

			<div className="breadcrumb">
				<div className="constrained">
					<ul>
						<li><Link to="/">home</Link></li>
						<li>time off</li>
					</ul>
				</div>
			</div>

			<main>
				<div className="constrained">
					<div class="flexwrap pagecolumns">
						<div className="glancewrap">
							{ (pendingApprovals > 0) &&
								<PendingApprovals onChangeApprovals={approvalHandler} />
							}
							<AtAGlance />
						</div>
						<div className="calendarwrap">
							<Calendar />
							
							<div className="directreportwrap">
							{ status === 'completed' &&
								directReports.map((employee, index) => 
									<AtAGlance key={employee.employeeId} id={employee.employeeId} showHeading={(index != 0) ? false : true} />
								)
							}
							</div>
							
						</div>
					</div>	
				</div>
			</main>
		</>
	)
}

export default TimeOffTracker;