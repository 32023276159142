/**
***************************************************************************
* NAME 			: RemainingTime
* PROPS	 		: None
* RETURNS 		: Displays vacation and personal time remaining for the
*				: employee's current use year
* LAST MODIFIED 	: February 14, 2022
***************************************************************************
*/

import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../../context/auth-context';
import useHttp from '../../../hooks/use-http';
import { getYearSummary, getNextVacation } from '../../../api/time-off-tracker';
import LoadingSpinner from '../../UI/LoadingSpinner';

const RemainingTime = () => {
	const authCtx = useContext(AuthContext);

	const [summary, setSummary] = useState(null);
	const [daysRemaining, setDaysRemaining] = useState(0);
	const [daysDisplay, setDaysDisplay] = useState(' days');
	const [vacationMessage, setVacationMessage] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState(null);

	const { sendRequest, status, data, error } = useHttp(getYearSummary, true);
	const { sendRequest: vacationRequest, status: vacationStatus, data: vacationData, error: vacationError } = useHttp(getNextVacation, true);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				setErrorMessage(error);
			} else if (!data['success']) {
				setErrorMessage(data['status_code'] + ': ' + data['status']);
			} else {
				// console.log('REMAINING TIME DATA');
				// console.log(data['data']);
				if (data['status_code'] != 200) {
					setErrorMessage(data['status'])
				} else {
					setSummary(data['data']);
				}
			}
			setIsLoading(false);
		}
	}, [status, error, data]);

	useEffect(() => {
		if (vacationStatus === 'completed') {
			if (vacationError !== null) {
				// setErrorMessage(vacationError);
			} else if (!vacationData['success']) {
				// setErrorMessage(vacationData['status_code'] + ': ' + vacationData['status']);
			} else {
				// console.log('VACATION DATA');
				// console.log(vacationData['data']);

				if ((vacationData['data']['inVacation'] == 0) && (vacationData['data']['daysRemaining'] != 0)) {
					if (vacationData['data']['daysRemaining'] >= 28) {
						setVacationMessage('It will be here before you know it!');
					} else if (vacationData['data']['daysRemaining'] <= 27 && vacationData['data']['daysRemaining'] > 21) {
						setVacationMessage('Less than a month to go!');
					} else if (vacationData['data']['daysRemaining'] == 21) {
						setVacationMessage('Three more weeks!');
					} else if (vacationData['data']['daysRemaining'] <= 20 && vacationData['data']['daysRemaining'] > 14) {
						setVacationMessage('Less than three weeks to go!');
					} else if (vacationData['data']['daysRemaining'] == 14) {
						setVacationMessage('Two more weeks!');
					} else if (vacationData['data']['daysRemaining'] <= 13 && vacationData['data']['daysRemaining'] > 7) {
						setVacationMessage('Less than two weeks to go!');
					} else {
						setVacationMessage('Get ready!');
					}
					if (vacationData['data']['daysRemaining'] == 1) {
						setDaysDisplay(' day');
					}

					setDaysRemaining(vacationData['data']['daysRemaining']);
				}
			}
		}
	}, [vacationStatus, vacationError, vacationData]);

	useEffect(() => {
		const requestData = {
			token: authCtx.token
		};
		sendRequest(requestData);
		vacationRequest(requestData);
	}, [sendRequest, vacationRequest]);

	return (
		<div className="pageitem column vacation-tracker has-linkcard">
			<div>
				<h2>your remaining<br />time off</h2>

				{
					isLoading && (
						<div>
							<LoadingSpinner />
						</div>
					)
				}
				{
					!isLoading && (
						<>
							{ errorMessage }
							{(summary != null) && 
								<ul className="vacation-tracker_list">
									<li><b>{ summary.vacationremaining }</b> vacation days</li>
									<li><b>{ summary.personalremaining }</b> personal days</li>
								</ul>
							}
						</>
					)
				}
				{
					daysRemaining != 0 && (
						<>
							<div className="vaca-illustration">
								<img src="/img/vacay-illustration.svg" alt="" width="245" height="60" />
							</div>
							<p>
								{
									daysRemaining > 7 && 
									<>
										<strong>{daysRemaining}</strong> more {daysDisplay} until your next vacation! {vacationMessage}
									</>
								}
								{
									daysRemaining <= 7 && 
									<>
										<strong>{vacationMessage}</strong> Only <strong>{daysRemaining}</strong> more {daysDisplay} until your next vacation!
									</>
								}
							</p>
						</>
					)
				}
			</div>
			<Link to="/time-off-tracker/" className="link-card arrow">
				<div>
					<p>time off</p>
					<img src="/img/card-arrow-white.svg" alt="" width="36" height="29" />
				</div>
			</Link>
		</div>
	)
}

export default RemainingTime;
