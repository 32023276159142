import { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom'; // Sets css class on the active anchor item
import AuthContext from '../../../context/auth-context';
import NotificationContext from '../../../context/notification-context';
import useHttp from '../../../hooks/use-http';
import { getRole } from '../../../api/employee';
// import Button from '../../UI/Button';

// import '../../../css/screen.css';
import classes from './Navigation.module.css';

const Navigation = () => {
	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);

	const [role, setRole] = useState('');
	const [navClass, setNavClass] = useState('menu-icon');
	const [ariaExpanded, setAriaExpanded] = useState('false');

	const { sendRequest: roleRequest, status: roleStatus, data: roleData, error: roleError } = useHttp(getRole, true);

	/**
	**********
	ROLE
	**********
	*/
	useEffect(() => {
		if (roleStatus === 'completed') {
			if (roleError !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					roleError
				)
			} else if (!roleData['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					roleData['status_code'] + ': ' + roleData['status']
				)
			} else {
				// console.log('ROLE DATA');
				// console.log(roleData['data']);

				setRole(roleData['data']['role']);
			}
		}
	}, [roleStatus, roleError, roleData]);

	useEffect(() => {
		if ((typeof authCtx.token !== 'undefined') && (authCtx.trustedBrowser)) {
			if (authCtx.token !== null) {
				const requestData = {
					token: authCtx.token
				};

				roleRequest(requestData);
			}
		}
	}, [roleRequest, authCtx.token, authCtx.trustedBrowser]);

	/**
	**********
	HANDLERS
	**********
	*/
	const navigationHandler = () => {
		if (navClass != 'menu-icon open') {
			setNavClass('menu-icon open');
			setAriaExpanded('true');
		} else {
			setNavClass('menu-icon close');
			setAriaExpanded('false');
		}
	};

	const logoutHandler = () => {
		setNavClass('menu-icon close');
		setAriaExpanded('false');
		authCtx.logout();
	};

	return (
		<div className="navigation-wrap">
			<button className={navClass} aria-controls="navbar" aria-expanded={ariaExpanded} onClick={navigationHandler}>
				<span className="burger-layer"></span>
				<span className="burger-layer"></span>
				<span className="burger-layer"></span>
				<span className="text">menu</span>
			</button>
			<nav id="navbar">
				<ul>
{/*
					<li>
						<NavLink to="/hr-stuff/" activeClassName={classes.active} onClick={navigationHandler}>
							<div className="icn hrstuff">
								<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
									<path d="M21 42A21 21 0 100 21a21.06174 21.06174 0 0021 21zm0-39.375A18.375 18.375 0 112.625 21 18.429 18.429 0 0121 2.625zM9.45 27.36563a1.29433 1.29433 0 112.23125-1.3125 10.81676 10.81676 0 0018.70312.13125 1.22217 1.22217 0 011.77188-.45938 1.28732 1.28732 0 01.45937 1.77188 13.44612 13.44612 0 01-11.55 6.62812A13.2968 13.2968 0 019.45 27.36563zm1.05-9.05626a3.9375 3.9375 0 017.875 0 1.3125 1.3125 0 01-2.625 0 1.3125 1.3125 0 00-2.625 0 1.3125 1.3125 0 01-2.625 0zm13.125 0a3.9375 3.9375 0 017.875 0 1.3125 1.3125 0 01-2.625 0 1.3125 1.3125 0 00-2.625 0 1.3125 1.3125 0 01-2.625 0z" fill="#fff" className="theicon" />
									<path fill="none" d="M0 0h42v42H0z" />
								</svg>
							</div><br />
							hr stuff
						</NavLink>
					</li>
					<li>
						<NavLink to="/resource-full/" activeClassName={classes.active} onClick={navigationHandler}>
							<div className="icn tools">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42" width="42" height="42">
									<path clip-rule="evenodd" fill="none" d="M0 0h42v42H0z" />
									<path d="M26.9 11.4c-.8-2.2-.2-4.7 1.4-6.3 1.2-1.2 2.9-1.9 4.6-1.8l-2.8 4.4 4.1 4.1L38.6 9c.1 1.7-.5 3.4-1.8 4.6-1.6 1.6-4.1 2.2-6.3 1.4l-.7-.2-4.3 4.3-2.7-2.7 4.3-4.3-.2-.7zm-14-3.7l-.5 1.5 11.5 11.5-3.1 3.1L9.3 12.3l-1.5.5L4 6.2 6.2 4l6.7 3.7zm2.2 22.9c.8 2.2.2 4.7-1.4 6.3-1.2 1.2-2.9 1.9-4.6 1.8l2.8-4.4-4.1-4.1L3.4 33c-.1-1.7.5-3.4 1.8-4.6 1.6-1.6 4.1-2.2 6.3-1.4l.7.2 4.3-4.3 2.7 2.7-4.3 4.3.2.7zm18.3 7L21.9 26.1l4.2-4.2 11.5 11.5c.1 0-4.2 4.2-4.2 4.2zm-2.9-20.1c1.3.3 2.6.3 3.9.1 1.7-.4 3.2-1.2 4.4-2.5 2.1-2.3 2.7-5.6 1.7-8.5l-.4-1.2-5.5 3.5-1.5-1.5 3.5-5.5-1.2-.4c-2.9-1-6.2-.4-8.5 1.7-1.3 1.2-2.2 2.7-2.5 4.4-.3 1.3-.3 2.6.1 3.9l-3.3 3.3L15 8.7l.7-2L5.9 1 1.1 5.8l5.6 9.9 2-.7 6.1 6.1-3.3 3.3c-1.3-.3-2.6-.3-3.9-.1-1.7.4-3.2 1.2-4.4 2.5-2.1 2.3-2.7 5.6-1.7 8.5l.4 1.2L7.4 33l1.5 1.5L5.4 40l1.4.5c.9.3 1.7.5 2.6.5 2.2 0 4.3-.9 5.9-2.5 1.1-1.1 1.9-2.6 2.3-4.2.3-1.3.3-2.6-.1-3.9l2.7-2.7 11.6 11.6c.9.9 2.3.9 3.2 0l4.3-4.3c.9-.9.9-2.3 0-3.2L27.8 20.2l2.7-2.7z" fill-rule="evenodd" clip-rule="evenodd" fill="#e9eaf0" className="theicon" />
								</svg>
							</div><br />
							resource-full
						</NavLink>
					</li>
					<li>
						<NavLink to="/tech-know/" activeClassName={classes.active} onClick={navigationHandler}>
							<div className="icn tech">
								<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
									<path d="M38.26159 18.12893l.01181-15.95886a2.15368 2.15368 0 00-2.14959-2.153L12.46125 0h-.00211a2.15172 2.15172 0 00-2.1509 2.14959l-.01182 15.95912-3.23357 9.67789-.00158 2.32553c0 .0129.00355.02481.00378.03765l-6.53816-.00457-.0084 11.83142 7.52882.00525.00141-1.93929a14.37553 14.37553 0 011.66974.75132 8.45724 8.45724 0 003.70677 1.19191L32.78572 42h.001a2.33266 2.33266 0 001.85234-1.21187 2.252 2.252 0 00.005-2.30373c-1.02425-1.77658-4.46816-2.73226-10.48536-2.912a4.6298 4.6298 0 00-1.88657-3.29784l17.05476.01234h.0021a2.15171 2.15171 0 002.1509-2.14959l.00158-2.32579zM12.45941 2.15117l23.66283.01706-.0109 15.05817-23.66283-.01707zm-.31278 17.2091l24.26348.01753 2.50438 7.53092-29.28285-.02121zM5.89772 39.82914L2.67123 39.827l.00525-7.529 3.22675.0021zm26.9077-.21244c.01313.058-.07458.17358-.14863.23213l-19.23-.01418a6.88755 6.88755 0 01-2.74515-.964 11.85565 11.85565 0 00-2.63129-1.06252l.00332-4.51808a13.07082 13.07082 0 001.76614-.41073 11.5898 11.5898 0 013.60252-.57377h.01a19.17548 19.17548 0 015.70957.75575c2.182.729 2.71761 1.78422 2.84924 2.47671a19.63434 19.63434 0 01-7.04286-.98518 1.0756 1.0756 0 10-.88547 1.96052c.10609.04779 2.65745 1.17274 7.97024 1.17642 10.05706.00737 10.76795 1.90776 10.77237 1.92693zm6.52335-9.48094L9.21244 30.114l.00085-1.07559 30.11633.0218zm-25.80954-6.47214a1.07564 1.07564 0 011.07559-1.0748h.00079l19.36049.01392a1.07559 1.07559 0 01-.00078 2.15117h-.00079L14.594 24.74a1.07565 1.07565 0 01-1.07477-1.07638z" fill="#fff" className="theicon" />
									<path fill="none" d="M0 0h42v42H0z" />
								</svg>
							</div><br />
							tech know
						</NavLink>
					</li>
					<li>
						<NavLink to="/workflow/" activeClassName={classes.active} onClick={navigationHandler}>
							<div className="icn insideml">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42" width="42" height="42">
									<path clip-rule="evenodd" fill="none" d="M0 0h42v42H0z" />
									<path d="M21.2 1.3l.3.2L42 13.1 35.1 17l6.9 4-6.9 3.9 6.9 3.9-20.8 11.9L.4 28.8l6.9-3.9L.4 21l6.9-4-6.9-3.9L21.2 1.3zm11.3 25.1l-11.3 6.5-11.3-6.5-4.3 2.4 15.6 8.9 15.6-8.9-4.3-2.4zm-23-7.9l-1.1.6c.3.1.5.5.5.8 0 .5-.4.9-.9.9s-.9-.4-.9-.9v-.1l-1.8 1 .5.3h.1c.4 0 .7.2.8.5l4.7 2.7v-.1c0-.5.3-.8.8-.9h.3c.4.1.7.4.7.9 0 .4-.2.7-.5.8l1.2.7c.2-.1.4-.2.6-.2.5 0 .9.4.9.9v.2l5.9 3.3 3.2-1.9c.2-.2.4-.4.7-.4l1.4-.8c-.1-.1-.1-.2-.1-.3v-.1c0-.5.4-.9.9-.9.3 0 .6.2.8.5l1.5-.8h-.1c-.5 0-.8-.3-.9-.8v-.1c0-.5.4-.9.9-.9s.9.4.9.9c0 .2-.1.4-.2.5L35 22c0-.5.4-.8.9-.8.1 0 .3 0 .4.1l.5-.3-2.2-1.2v.2c0 .5-.4.9-.9.9s-.9-.4-.9-.9c0-.4.3-.7.6-.8l-1-.5-2.1 1.2v.2c0 .5-.4.9-.9.9-.3 0-.5-.1-.7-.3l-1.2.7c.3.1.6.5.6.8v.1c0 .4-.4.8-.9.8s-.9-.4-.9-.9V22l-4.7 2.6c-.1.4-.4.7-.9.7s-.9-.4-.9-.9v-.1c-.9-.4-2.4-1.2-4.9-2.6.1.1.2.3.2.5 0 .5-.4.9-.9.9s-.9-.4-.9-.9.4-.9.9-.9l-1.2-.9c-.2.2-.4.4-.7.4-.5 0-.9-.4-.9-.9 0-.1 0-.2.1-.3l-2-1.1zm11.4 9.1c.5 0 .9.4.9.9s-.4.9-.9.9-.9-.4-.9-.9.4-.9.9-.9zm-2.2-2.1c.5 0 .9.4.9.9s-.4.9-.9.9-.9-.4-.9-.9.4-.9.9-.9zm4.3 0c.5 0 .9.4.9.9s-.4.9-.9.9-.9-.4-.9-.9.4-.9.9-.9zm-6.4-2.2c.5 0 .9.4.9.9s-.4.9-.9.9-.9-.4-.9-.9.4-.9.9-.9zm8.6 0c.5 0 .9.4.9.9s-.4.9-.9.9-.9-.4-.9-.9.4-.9.9-.9zm-15-2.1c.5 0 .9.4.9.9s-.4.9-.9.9-.9-.4-.9-.9.4-.9.9-.9zm21.4 0c.5 0 .9.4.9.9s-.4.9-.9.9-.9-.4-.9-.9.4-.9.9-.9zM21.2 4.3L5.6 13.1 21.2 22l15.6-8.9-15.6-8.8z" fill-rule="evenodd" clip-rule="evenodd" fill="#e9eaf0" className="theicon" />
								</svg>
							</div><br />
							workflow
						</NavLink>
					</li>
*/}
					<li>
						<NavLink to="/time-off-tracker/" activeClassName={classes.active} onClick={navigationHandler}>
							<div className="icn tracker">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42" width="42" height="42">
									<path clip-rule="evenodd" fill="none" d="M0 0h42v42H0z" />
									<path d="M41 29.3v9.1H29.7v-9.1H41zm-2.4 2.4h-6.5V36h6.5v-4.3zM41 16.5v9.1H5.5C3 25.5 1 23.5 1 21s2-4.5 4.5-4.5H41zm-2.4 2.4H5.5c-1.2 0-2.1.9-2.1 2.1 0 1.2.9 2.1 2.1 2.1h33.1v-4.2zM27.5 3.6v9.1H1V3.6h26.5zM25.1 6H3.4v4.2h21.7V6z" fill-rule="evenodd" clip-rule="evenodd" fill="#e9eaf0" className="theicon" />
								</svg>
							</div><br />
							time off
						</NavLink>
					</li>
					{ ((role == 'Human Resources') || (role == 'Administrator')) &&
						<li>
							<NavLink to="/logician-lookup/" activeClassName={classes.active} onClick={navigationHandler}>
								<div className="icn hrstuff">
									<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"><g data-name="Layer 2"><g data-name="Layer 1"><path d="M34.94531 17.94041h-.20418l-.00732-.01843-5.17036-11.3604a3.83851 3.83851 0 00-4.73632-2.03628l-2.30779.776a1.04043 1.04043 0 00-.659 1.32339 1.05738 1.05738 0 001.338.65182l2.30786-.776a1.72934 1.72934 0 012.13392.9174l4.789 10.52249h-9.80994a3.57516 3.57516 0 00-3.41418 2.49095h-2.41a3.57519 3.57519 0 00-3.41424-2.49094H3.571L8.36 7.418a1.72946 1.72946 0 012.134-.91747l2.3078.776a1.05723 1.05723 0 001.338-.65182 1.04037 1.04037 0 00-.659-1.32339l-2.30779-.776a3.83823 3.83823 0 00-4.73646 2.03626L1.26619 17.922l-.00731.01843h-.20419A1.049 1.049 0 000 18.98355v4.31226a8.47756 8.47756 0 0016.9541 0v-.77818h2.0919v.77818A8.44055 8.44055 0 0027.523 31.68h.00007A8.44051 8.44051 0 0036 23.29581v-4.31227a1.049 1.049 0 00-1.05469-1.04313zm-20.10065 5.3554A6.34024 6.34024 0 018.477 29.59372h-.00006a6.34023 6.34023 0 01-6.36757-6.29791v-3.26913h11.27145a1.45755 1.45755 0 011.46384 1.44781zm19.046 0a6.36806 6.36806 0 01-12.73535 0v-1.82132a1.4576 1.4576 0 011.46384-1.44781h11.27147z" fill="#fff" className="theicon"/><path fill="none" d="M0 0h36v36H0z"/></g></g></svg>
								</div><br />
								logician lookup
							</NavLink>
						</li>
					}
					{ ((role == 'Human Resources') || (role == 'President') || (role == 'Administrator')) &&
						<li>
							<NavLink to="/reports/" activeClassName={classes.active} onClick={navigationHandler}>
								<div className="icn hrstuff">
									<svg data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42"><defs></defs><g id="c" data-name="Layer 1"><path fill="#fff" class="e" d="M36.709 15.526V1.541C36.709.69 36.019 0 35.168 0H14.434L5.291 9.143v31.316c0 .851.69 1.541 1.541 1.541h28.337c.851 0 1.541-.69 1.541-1.541V15.526ZM14.533 3.381v5.862H8.672l5.861-5.862Zm19.715 36.158H7.752V11.703h9.242V2.461h17.254v37.078Z" className="theicon"/><path d="M21.128 9.242h8.813c.68 0 1.23.551 1.23 1.23 0 .68-.551 1.23-1.23 1.23h-8.813c-.68 0-1.23-.551-1.23-1.23 0-.68.551-1.23 1.23-1.23ZM13.077 16.931h16.864c.68 0 1.23.551 1.23 1.23 0 .68-.551 1.23-1.23 1.23H13.077c-.68 0-1.23-.551-1.23-1.23 0-.68.551-1.23 1.23-1.23ZM13.077 24.619h16.864c.68 0 1.23.551 1.23 1.23 0 .68-.551 1.23-1.23 1.23H13.077c-.68 0-1.23-.551-1.23-1.23 0-.68.551-1.23 1.23-1.23Z" className="theicon"/><path fill="none" d="M0 0h42v42H0z"/></g></svg>
								</div><br />
								reports
							</NavLink>
						</li>
					}
					{ ((role == 'President') || (role == 'Administrator')) &&
						<li>
							<NavLink to="/send-sms/" activeClassName={classes.active} onClick={navigationHandler}>
								<div className="icn hrstuff">
									<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42"><g id="Layer_1-2" data-name="Layer 1"><path fill="none" d="M0 0h42v42H0z"/><path class="cls-2" d="M11.66667 37.33333c-.175 0-.35729-.04375-.525-.12396-.39375-.19688-.64167-.60521-.64167-1.04271v-5.91354c-3.95208-.56875-7-3.97396-7-8.08646v-9.33333c0-4.50625 3.66042-8.16667 8.16667-8.16667h18.66666c4.50625 0 8.16667 3.66042 8.16667 8.16667v9.33333c0 4.50625-3.66042 8.16667-8.16667 8.16667h-8.94688L12.36666 37.1c-.20417.15313-.45208.23333-.7.23333Zm0-30.33333c-3.21562 0-5.83333 2.61771-5.83333 5.83333v9.33333c0 3.21563 2.61771 5.83333 5.83333 5.83333.64167 0 1.16667.525 1.16667 1.16667v4.66667l7.46667-5.6c.20417-.15313.44479-.23333.7-.23333h9.33333c3.21563 0 5.83333-2.61771 5.83333-5.83333v-9.33334C36.16667 9.61771 33.54896 7 30.33334 7H11.66667Z" fill="#fff" className="theicon"/><path class="cls-2" d="M21 21c-1.93229 0-3.5-1.56771-3.5-3.5S19.06771 14 21 14s3.5 1.56771 3.5 3.5S22.93229 21 21 21Zm0-4.66667c-.64167 0-1.16667.525-1.16667 1.16667s.525 1.16667 1.16667 1.16667 1.16667-.525 1.16667-1.16667-.525-1.16667-1.16667-1.16667ZM11.66667 21c-1.93229 0-3.5-1.56771-3.5-3.5s1.56771-3.5 3.5-3.5 3.5 1.56771 3.5 3.5-1.56771 3.5-3.5 3.5Zm0-4.66667c-.64167 0-1.16667.525-1.16667 1.16667s.525 1.16667 1.16667 1.16667 1.16667-.525 1.16667-1.16667-.525-1.16667-1.16667-1.16667ZM30.33333 21c-1.93229 0-3.5-1.56771-3.5-3.5s1.56771-3.5 3.5-3.5 3.5 1.56771 3.5 3.5-1.56771 3.5-3.5 3.5Zm0-4.66667c-.64167 0-1.16667.525-1.16667 1.16667s.525 1.16667 1.16667 1.16667S31.5 18.14167 31.5 17.5s-.525-1.16667-1.16667-1.16667Z" fill="#fff" className="theicon"/></g></svg>
								</div><br />
								send sms
							</NavLink>
						</li>
					}
					<li>
						<NavLink to="/account/" activeClassName={classes.active} onClick={navigationHandler}>
							<div className="icn account">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42" width="42" height="42">
									<path clip-rule="evenodd" fill="none" d="M0 0h42v42H0z" />
									<path d="M20.9 21c-4.7 0-8.4-3.8-8.4-8.4 0-4.7 3.8-8.4 8.4-8.4s8.4 3.8 8.4 8.4c.1 4.6-3.7 8.4-8.4 8.4m14.2 5.9c-2.2-2.2-4.8-3.8-7.6-4.8 3-2.1 5-5.5 5-9.3.1-6.2-4.9-11.6-11.1-11.8-6.6-.3-12 5-12 11.6 0 3.9 2 7.4 5 9.5-2.8 1-5.4 2.6-7.6 4.8-3.4 3.3-5.4 7.7-5.8 12.4-.1.9.7 1.7 1.6 1.7.8 0 1.5-.6 1.5-1.4.7-8.6 8-15.4 16.8-15.4S37 31 37.7 39.6c.1.8.7 1.4 1.5 1.4.9 0 1.6-.8 1.6-1.7-.3-4.7-2.3-9.1-5.7-12.4" fill-rule="evenodd" clip-rule="evenodd" fill="#e9eaf0" className="theicon" />
								</svg>
							</div><br />
							account
						</NavLink>
					</li>
					<li className="logoutlink">
						<NavLink to="/" activeClassName={classes.active} onClick={logoutHandler}>
							<div className="icn logout">
								<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
									<path fill="none" d="M0 0h17v17H0z" />
									<path d="M3.95571 9.05093h11.4061l-2.09343 2.09346.77909.77908L16.667 9.30394a1.13691 1.13691 0 000-1.60785l-2.61953-2.61954-.77909.77909 2.09343 2.09346H3.95571A.55091.55091 0 003.4048 8.5a.55092.55092 0 00.55091.55093zm-2.30295 6.50072h8.78193a1.6546 1.6546 0 001.65274-1.65273v-2.20365h-1.10182v2.20365a.55154.55154 0 01-.55092.55091H1.65274a.55154.55154 0 01-.55092-.55091V3.10108a.55154.55154 0 01.55092-.55091h8.782a.55154.55154 0 01.55092.55091v2.20365h1.10182V3.10108a1.6546 1.6546 0 00-1.65274-1.65273h-8.782A1.6546 1.6546 0 000 3.10108v10.79784a1.65464 1.65464 0 001.65276 1.65273z" fill="#e9eaf0" className="theicon" />
								</svg>
							</div>
							logout
						</NavLink>
					</li>
				</ul>
			</nav>
		</div>




/*
		<header className={classes.header}>
			<nav>
				<ul>
					<li>
						<NavLink to="/hr-stuff/" activeClassName={classes.active}>
							hr stuff
						</NavLink>
					</li>
					<li>
						<NavLink to="/resource-full/" activeClassName={classes.active}>
							resource-full
						</NavLink>
					</li>
					<li>
						<NavLink to="/tech-know/" activeClassName={classes.active}>
							tech-know
						</NavLink>
					</li>
					<li>
						<NavLink to="/workflow/" activeClassName={classes.active}>
							workflow
						</NavLink>
					</li>
					<li>
						<NavLink to="/hr-stuff/vacation-tracker/" activeClassName={classes.active}>
							vacation-tracker
						</NavLink>
					</li>
					<li>
						<NavLink to="/account/" activeClassName={classes.active}>
							account
						</NavLink>
					</li>
					<li>
						<Button onClick={authCtx.logout}>logout</Button>
					</li>
				</ul>
			</nav>
		</header>
*/
	)
}

export default Navigation;