import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../../context/auth-context';
import useHttp from '../../../hooks/use-http';
import { getWeather } from '../../../api/header';

let tickTimer;

const Weather = (props) => {
	// console.log('WEATHER PROPS');
	// console.log(props.content);

	const authCtx = useContext(AuthContext);

	const [currentTemperature, setCurrentTemperature] = useState(null);
	const [currentDescription, setCurrentDescription] = useState(null);
	const [currentIcon, setCurrentIcon] = useState(null);

	const requestData = {
		token: authCtx.token
	};

	const { sendRequest, status, data, error } = useHttp(getWeather, true);

	const tick = (timeDifference) => {
		// To sync local with the server clock, this will run every second until it's on the minute
// console.log('TICK');
		const today = new Date(new Date().getTime() - timeDifference);
			
		const seconds = today.getSeconds();
		const minutes = today.getMinutes();

		if (seconds == 0) {
			const minuteLastDigit = minutes % 10;
			if ((minuteLastDigit == 1) || (minuteLastDigit == 6)) {
				sendRequest(requestData);

// console.log('TICK MINUTE: ' + minutes);
				// Recursive call, keeps the clock ticking.
				clearTimeout(tickTimer);
				tickTimer = setTimeout(() => tick(timeDifference), 300000);
			} else {
// console.log('TICK MINUTE: ' + minutes);
				// Recursive call, keeps the clock ticking.
				clearTimeout(tickTimer);
				tickTimer = setTimeout(() => tick(timeDifference), 60000);
			}
		} else {
// console.log('TICK SECONDS: ' + seconds);
			// Recursive call, keeps the clock ticking.
			clearTimeout(tickTimer);
			tickTimer = setTimeout(() => tick(timeDifference), 1000);
		}
	}

	const clock = (serverClock) => {
		const today = new Date();
		const todaysYear = today.getFullYear() - 2000;
		const todaysMonth = today.getMonth() + 1;
		
		const sToday = new Date(serverClock);
		const sTodaysYear = sToday.getFullYear() - 2000;
		const sTodaysMonth = sToday.getMonth() + 1;
		
		//Computes the time difference between the client computer and the server.
		const serverDate = (new Date(sTodaysYear, sTodaysMonth, sToday.getDate(), sToday.getHours(), sToday.getMinutes(), sToday.getSeconds())).getTime();
		const clientDate = (new Date(todaysYear, todaysMonth, today.getDate(), today.getHours(), today.getMinutes(), today.getSeconds())).getTime();
		
		tick(clientDate - serverDate);
	}

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				// console.log(error);
			} else if (!data['success']) {
				// console.log(data['status_code'] + ': ' + data['status']);
			} else {
				// console.log('Good');
				setCurrentTemperature(data['data']['temperature']);
				setCurrentDescription(data['data']['description']);
				// setCurrentIcon("https://openweathermap.org/img/wn/" + data['data']['icon'] + "@2x.png");
				setCurrentIcon("/img/weather/" + data['data']['icon'] + "@2x.png");
			}
		}
	}, [status, error, data]);

	useEffect(() => {
		sendRequest(requestData);
	}, [sendRequest]);

	useEffect(() => {
		clock(props.content);
	}, [props.content]);

	return (
		<div className="weather">
			<Link to="/weather/" className="weatherlink">
				<p className="temp">{currentTemperature}&deg;</p>
				<div className="current-conditions">
					<p className="condition_description">{currentDescription}</p>
					<div className="condition_image"><img src={currentIcon} alt="" width="50" height="50" /></div>
				</div>
			</Link>
		</div>
	)
}

export default Weather;