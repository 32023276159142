import { useState, useContext, useEffect, useCallback } from 'react';
import AuthContext from '../../../context/auth-context'
import NotificationContext from '../../../context/notification-context';
import LoadingSpinner from '../../UI/LoadingSpinner';
import useInput from '../../../hooks/use-input';
import useHttp from '../../../hooks/use-http';
import { checkBrowser, trustBrowser, resendPin } from '../../../api/authentication';
// import TextField from '@material-ui/core/TextField';

let isMounted = true;
let resendTimer;
const showPinResend = 60000; // 60 seconds

const BrowserForm = (props) => {
	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);
	const [formProcessing, setFormProcessing] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	const [showResend, setShowResend] = useState(false);

	const { sendRequest: sendInit, status: initStatus, data: initData, error: initError } = useHttp(checkBrowser);
	const { sendRequest, status, data, error } = useHttp(trustBrowser);
	const { sendRequest: sendResend, status: resetStatus, data: resetData, error: resetError } = useHttp(resendPin);

	// If the page is refreshed (manually), check to see if the browser token has been set
	// If so, you shouldn't be here
	if ((initStatus === 'completed') && (initError === null) && (initData['data']['trusted'])) {
		authCtx.trustBrowser(true);
	};

	const displayNotification = useCallback(() => {
// console.log('NOTIFY DISPLAY');
		notifyCtx.displayNotification(
			"OK",
			"Your new PIN has been sent"
		)
	}, []); // If the notifyCtx dependancy is added, it will go into a loop (and not work)

	useEffect(() => {
		const tData = {
			token: authCtx.token
		};

		sendInit(tData);
	}, [sendInit, authCtx.token]);

	useEffect(() => {
		if (status === 'completed') {
// console.log(data);
			if (error !== null) {
				setErrorMessage(error);
			} else if (!data['success']) {
				setErrorMessage(data['status_code'] + ': ' + data['status']);
			} else if (!data['data']['valid']) {
				setErrorMessage(data['data']['message']);
			} else {
				// Cookie was set, trust browser
				isMounted = false;
				// clearTimeout(resendTimer);
				authCtx.trustBrowser(true);
			}
		}
	}, [status, error, data, authCtx]);

	useEffect(() => {
		if (resetStatus === 'completed') {
// console.log(data);
			if (resetError !== null) {
				setErrorMessage(resetError);
			} else if (!resetData['success']) {
				setErrorMessage(resetData['status_code'] + ': ' + resetData['status']);
			} else {
				// Pin was reset and sent
				displayNotification();
			}
		}
	}, [resetStatus, resetError, resetData, displayNotification]);

	const showResendHandler = useCallback(() => {
		if (isMounted) {
			setShowResend(true);
			clearTimeout(resendTimer);
		}
	}, []);

	const resendPinHandler = () => {
		setShowResend(false);

		const pData = {
			token: authCtx.token
		};

		sendResend(pData);
	}

	useEffect(() => {
		isMounted = true;
		if (resendTimer) {
			clearTimeout(resendTimer);
		}
		if (!showResend) {
			resendTimer = setTimeout(showResendHandler, showPinResend)
		} 
		// if (isMounted && !showResend) {
		// 	if (resendTimer) {
		// 		clearTimeout(resendTimer);
		// 	}
		// 	resendTimer = setTimeout(showResendHandler, showPinResend)
		// } else if (!isMounted) {
		// 	if (resendTimer) {
		// 		clearTimeout(resendTimer);
		// 	}
		// } 
	}, [showResend, showResendHandler]);

	// Requirement types
	const isPin = value => (value.match(/^\d+$/) && value.length == 6); // Positive number with a length of 6

	const {
		value: enteredPin,
		isValid: pinIsValid,
		hasError: pinHasError,
		valueChangeHandler: pinChangedHandler,
		inputBlurHandler: pinBlurHandler,
		valueSubmitHandler: pinSubmitHandler
		// reset: resetPin
	} = useInput(isPin); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	let formIsValid = false;
	if (pinIsValid) { // And any other form fields
		formIsValid = true;
	}

	const logoutHandler = () => {
		if (isMounted) {
			isMounted = false;
			clearTimeout(resendTimer);
			authCtx.logout();
		}
	}

	const submitHandler = event => {
		event.preventDefault();

		setFormProcessing(true);

		// Added (if form is able to be submitted first before error checking)
 		pinSubmitHandler();

		if (!formIsValid) {
			setErrorMessage(null);
			setFormProcessing(false);
			return;
		}

		const formData = {
			token: authCtx.token,
			pin: enteredPin
		};

		sendRequest(formData);

		// props.onAddTest(testEntry);

		// Reset values
		// resetPin();
		
		setFormProcessing(false);
		// lnameRef.current.value = ''; // Not ideal but doable (because you're directly manipulating the DOM)
	}

	const initMessage = props.initMessage;
	const codeInputClasses = pinHasError ? 'form-control invalid' : 'form-control';

	return (
		<div className="formwrap">
			{
				<p className="instructions">{initMessage}</p>
			}
			{
				<p className="error-text">{errorMessage}</p>
			}
			<form onSubmit={submitHandler}>
				<div className={codeInputClasses}>
					<label htmlFor='pin'>one-time code</label>
					<input
						type="text"
						name="pin"
						id="pin"
						onChange={pinChangedHandler}
						onBlur={pinBlurHandler}
						value={enteredPin}
					/>
					{pinHasError &&
						// <p className="error-text">Email must include @.</p>
						<p className="error-text">Valid one-time code required.</p>
					}
				</div>

				<div className="button-wrap">
					<div>
					<p><a onClick={logoutHandler}>back to login</a></p>
					{ showResend &&
						<p><a onClick={resendPinHandler}>resend code</a></p>
					}
					</div>
					{ formProcessing &&
						<div>
							<LoadingSpinner />
						</div>
					}
					{ !formProcessing &&
						<button type="submit" disabled={formProcessing}>verify code</button>
					}
				</div>
			</form>
		</div>
	)
}

export default BrowserForm;