import React, {useState} from 'react';

const NotificationContext = React.createContext({ // Could be a string but in this case, it's an object
	type: '',
	message: '',
	cssclass: '',
	icon: '',
	alt: '',
	dismiss: '',
	timeOut: 5000,
	displayNotification: () => {}, // Just doing this here for IDE help
	clearNotification: () => {} // Just doing this here for IDE help
});

export const NotificationContextProvider = (props) => {
	const [type, setType] = useState();
	const [message, setMessage] = useState();
	const [cssclass, setClass] = useState();
	const [icon, setIcon] = useState();
	const [alt, setAlt] = useState();
	const [dismiss, setDismiss] = useState();
	const [timeOut, setTimeOut] = useState();

	const notificationHandler = (type, message, timeOut, dismiss) => {
// console.log("DISPLAY MESSAGE");
		if ((typeof timeOut === 'undefined') || (typeof timeOut !== 'number')) {
			timeOut = 5000; // default 5 seconds
		}
		if (typeof dismiss === 'undefined') {
			dismiss = '';
		}
		setType(type);
		setMessage(message);
		setTimeOut(timeOut);
		setDismiss(dismiss);
		switch(type.toLowerCase()) {
			case 'ok':
				setClass('notificationbar success');
				setIcon('/img/notification/success-icn-white.svg');
				setAlt('Success');
				break;
			default:
				setClass('notificationbar ' + type.toLowerCase());
				setIcon('/img/notification/' + type.toLowerCase() + '-icn-white.svg');
				setAlt(type);
		}
	}

	const clearNotification = () => {
// console.log("CLEAR NOTIFICATION");
		setType(null);
		setMessage(null);
		setClass(null);
		setIcon(null);
		setAlt(null);
		setDismiss(null);
		setTimeOut(5000); // default
	}

	const contextValue = {
		type,
		message,
		cssclass,
		icon,
		alt,
		dismiss,
		timeOut,
		displayNotification: notificationHandler,
		clearNotification
	}

	return (
		<NotificationContext.Provider value={contextValue}>
			{props.children}
		</NotificationContext.Provider>
	)
};

export default NotificationContext;