import React, { useContext, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthContext from './context/auth-context'
import LoadingSpinner from './components/UI/LoadingSpinner';

import NotificationBar from './components/UI/NotificationBar';

import Header from './includes/Header';
import Footer from './includes/Footer';

// Pages
import Home from './pages/Index';
import Login from './pages/Login';

// Time Off Tracker
import TimeOffTracker from './pages/time-off-tracker/Index';

// Logician Lookup
import LogicianLookup from './pages/logician-lookup/Index';

// Account
import Account from './pages/account/Index';
  
// CSS
import './App.css';

// Lazy Loaded Imports (must come after regular imports)
const TimeOffTrackerRequest = React.lazy(() => import('./pages/time-off-tracker/request-time-off/Index'));
const TimeOffTrackerEmployee = React.lazy(() => import('./pages/time-off-tracker/Employee'));

const LogicianDetails = React.lazy(() => import('./pages/logician-lookup/Details'));

const Weather = React.lazy(() => import('./pages/weather/Index'));

const Reports = React.lazy(() => import('./pages/reports/Index'));

const SendSMS = React.lazy(() => import('./pages/send-sms/Index'));

// const DomainDirectory = React.lazy(() => import('./pages/resource-full/domain-directory/Index'));
// const DomainDetails = React.lazy(() => import('./pages/resource-full/domain-directory/Details'));

function App() {
	const authCtx = useContext(AuthContext);

	return (
		<>
		{/* <div className="App"> */}
			<Suspense
				fallback = {
					<div className='centered'>
						<LoadingSpinner />
					</div>
				}
			>
				{authCtx.isLoggedIn && authCtx.trustedBrowser &&
					<NotificationBar />
				}
				<Header />
				{(!authCtx.isLoggedIn || !authCtx.trustedBrowser) && <Login />}
				{authCtx.isLoggedIn && authCtx.trustedBrowser &&
					<Switch>
						<Route path="/" exact>
							<Home />
						</Route>

						<Route path="/time-off-tracker/" exact>
							<TimeOffTracker />
						</Route>
						<Route path="/time-off-tracker/request-time-off/" exact>
							<TimeOffTrackerRequest />
						</Route>
						<Route path="/time-off-tracker/request-time-off/:employeeId" exact>
							<TimeOffTrackerRequest />
						</Route>
						<Route path="/time-off-tracker/:employeeId" exact>
							<TimeOffTrackerEmployee />
						</Route>

						<Route path="/logician-lookup/" exact>
							<LogicianLookup />
						</Route>
						<Route path="/logician-lookup/:employeeId" exact>
							<LogicianDetails />
						</Route>

						<Route path="/weather/" exact>
							<Weather />
						</Route>

						<Route path="/reports/" exact>
							<Reports />
						</Route>

						<Route path="/send-sms/" exact>
							<SendSMS />
						</Route>

						<Route path="/account/" exact>
							<Account />
						</Route>
						<Route path="/account/:employeeId" exact>
							<Account />
						</Route>

{/*
						<Route path="/resource-full/domain-directory/" exact>
							<DomainDirectory />
						</Route>
						<Route path="/resource-full/domain-directory/:domainId" exact>
							<DomainDetails />
						</Route>
*/}

						<Route path="*">
							<Redirect to="/" />
						</Route>

						{/* <Route path="*">
							<NotFound />
						</Route> */}

					</Switch>
				}
				<Footer />
			</Suspense>
		</>
	);
}

export default App;
