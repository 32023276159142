const API_BASE_URL = process.env.REACT_APP_PHP_API_BASE_URL;

export async function getPeopleOut(pageData) {
	// console.log('PAGEDATA:');
	// console.log(pageData);
	const response = await fetch(`${API_BASE_URL}/time-off-tracker/getPeopleOut/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	// Check for unsuccessful response
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the listing.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}

	return data;
}

export async function getYearSummary(pageData) {
	// console.log('PAGEDATA:');
	// console.log(pageData);
	const response = await fetch(`${API_BASE_URL}/time-off-tracker/getYearSummary/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	// Check for unsuccessful response
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the summary.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}

	return data;
}

export async function getYearSummaries(pageData) {
	// console.log('PAGEDATA:');
	// console.log(pageData);
	const response = await fetch(`${API_BASE_URL}/time-off-tracker/getYearSummaries/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	// Check for unsuccessful response
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the year summaries.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}

	return data;
}

export async function getYearDetail(pageData) {
	// console.log('PAGEDATA:');
	// console.log(pageData);
	const response = await fetch(`${API_BASE_URL}/time-off-tracker/getYearDetail/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	// Check for unsuccessful response
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the details.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}

	return data;
}

export async function getNextVacation(pageData) {
	// console.log('PAGEDATA:');
	// console.log(pageData);
	const response = await fetch(`${API_BASE_URL}/time-off-tracker/getNextVacation/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	// Check for unsuccessful response
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the vacation details.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}

	return data;
}

export async function getTimeOffRequests(pageData) {
	// console.log('PAGEDATA:');
	// console.log(pageData);
	const response = await fetch(`${API_BASE_URL}/time-off-tracker/getTimeOffRequests/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	// Check for unsuccessful response
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the details.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}

	return data;
}

export async function getPendingApproval(pageData) {
	// console.log('PAGEDATA:');
	// console.log(pageData);
	const response = await fetch(`${API_BASE_URL}/time-off-tracker/getPendingApproval/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	// Check for unsuccessful response
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the request.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}

	return data;
}

export async function updateAllotment(pageData) {
	// console.log('PAGEDATA:');
	// console.log(pageData);
	const response = await fetch(`${API_BASE_URL}/time-off-tracker/updateAllotment/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	// Check for unsuccessful response
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not update the allotment.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}

	return data;
}

export async function updateRequest(pageData) {
	// console.log('PAGEDATA:');
	// console.log(pageData);
	const response = await fetch(`${API_BASE_URL}/time-off-tracker/updateRequest/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	// Check for unsuccessful response
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not update the request.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}

	return data;
}

export async function removeRequest(pageData) {
	// console.log('PAGEDATA:');
	// console.log(pageData);
	const response = await fetch(`${API_BASE_URL}/time-off-tracker/removeRequest/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	// Check for unsuccessful response
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not remove the request.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}

	return data;
}

export async function createUseYear(pageData) {
	// console.log('PAGEDATA:');
	// console.log(pageData);
	const response = await fetch(`${API_BASE_URL}/time-off-tracker/createUseYear/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	// Check for unsuccessful response
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not add the use year.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}

	return data;
}

export async function removeTimeOff(pageData) {
	// console.log('PAGEDATA:');
	// console.log(pageData);
	const response = await fetch(`${API_BASE_URL}/time-off-tracker/removeTimeOff/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	// Check for unsuccessful response
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not remove the time off.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}

	return data;
}

export async function getTypes(pageData) {
	// console.log('PAGEDATA:');
	// console.log(pageData);
	const response = await fetch(`${API_BASE_URL}/time-off-tracker/getTypes/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	// Check for unsuccessful response
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not get the time off types.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}

	return data;
}

export async function getCalendar(pageData) {
	// console.log('PAGEDATA:');
	// console.log(pageData);
	const response = await fetch(`${API_BASE_URL}/time-off-tracker/getCalendar/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	// Check for unsuccessful response
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the calendar.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}

	return data;
}

export async function getCalendarFilters(pageData) {
	// console.log('PAGEDATA:');
	// console.log(pageData);
	const response = await fetch(`${API_BASE_URL}/time-off-tracker/getCalendarFilters/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	// Check for unsuccessful response
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not get the calendar filters.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}

	return data;
}

export async function getCalendarGridSummary(pageData) {
	// console.log('PAGEDATA:');
	// console.log(pageData);
	const response = await fetch(`${API_BASE_URL}/time-off-tracker/getCalendarGridSummary/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	// Check for unsuccessful response
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not get the time off summary for the calendar.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}

	return data;
}

export async function createRequest(pageData) {
	// console.log('PAGEDATA:');
	// console.log(pageData);
	const response = await fetch(`${API_BASE_URL}/time-off-tracker/createRequest/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	// Check for unsuccessful response
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not create the time off request.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}

	return data;
}

