import { useState, useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AuthContext from '../../../context/auth-context'
import NotificationContext from '../../../context/notification-context';
import LoadingSpinner from '../../UI/LoadingSpinner';
import useInput from '../../../hooks/use-input';
import useHttp from '../../../hooks/use-http';
import { updatePosition } from '../../../api/account';
import { getEmployees, getTeams, getDepartments } from '../../../api/employee';

const AddressForm = (props) => {
	const params = useParams();

	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);
	const [employees, setEmployees] = useState([]);
	const [teams, setTeams] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [formProcessing, setFormProcessing] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const supervisorRef = useRef();
	const teamRef = useRef();
	const departmentRef = useRef();
	const desktopRef = useRef();

	let isHR = false;
	if (typeof params.employeeId !== 'undefined') {
		if (!isNaN(params.employeeId) && (params.employeeId > 0)) {
			isHR = true;
		}
	}

	const { sendRequest, status, data, error } = useHttp(updatePosition);
	const { sendRequest: employeeRequest, status: employeeStatus, data: employeeData, error: employeeError } = useHttp(getEmployees);
	const { sendRequest: teamRequest, status: teamStatus, data: teamData, error: teamError } = useHttp(getTeams);
	const { sendRequest: departmentRequest, status: departmentStatus, data: departmentData, error: departmentError } = useHttp(getDepartments);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					error
				)
			} else if (!data['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					data['status_code'] + ': ' + data['status']
				)
			} else {
				if (data['status_code'] == 200) {
					// Password reset
					notifyCtx.displayNotification(
						"OK",
						'The position has been updated'
					)

					// Return to log in
					props.onUpdate(true);
				} else {
					setErrorMessage(data['status']);
				}
			}
		}
	}, [status, error, data]);

	/**
	**********
	INIT
	**********
	*/
	useEffect(() => {
		if (employeeStatus === 'completed') {
			if (employeeError !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					employeeError
				)
			} else if (!employeeData['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					employeeData['status_code'] + ': ' + employeeData['status']
				)
			} else {
				// console.log('EMPLOYEES');
				// console.log(employeeData['data']);

				setEmployees(employeeData['data']);
			}
			// setIsLoading(false);
		}
	}, [employeeStatus, employeeError, employeeData]);

	useEffect(() => {
		if (teamStatus === 'completed') {
			if (teamError !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					teamError
				)
			} else if (!teamData['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					teamData['status_code'] + ': ' + teamData['status']
				)
			} else {
				// console.log('TEAMS');
				// console.log(teamData['data']);

				setTeams(teamData['data']);
			}
			// setIsLoading(false);
		}
	}, [teamStatus, teamError, teamData]);

	useEffect(() => {
		if (departmentStatus === 'completed') {
			if (departmentError !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					departmentError
				)
			} else if (!departmentData['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					departmentData['status_code'] + ': ' + departmentData['status']
				)
			} else {
				// console.log('DEPARTMENTS');
				// console.log(departmentData['data']);

				setDepartments(departmentData['data']);
			}
			// setIsLoading(false);
		}
	}, [departmentStatus, departmentError, departmentData]);

	useEffect(() => {
		const requestData = {
			token: authCtx.token
		};

		employeeRequest(requestData);
		teamRequest(requestData);
		departmentRequest(requestData);
		titleDefault(props.data.title);
		initialsDefault(props.data.initials);
		// extensionDefault(props.data.phoneExtension);
	}, []);

	// Requirement types
	const isRequired = value => value && (value != '');

	const {
		value: enteredTitle,
		isValid: titleIsValid,
		hasError: titleHasError,
		valueChangeHandler: titleChangedHandler,
		inputBlurHandler: titleBlurHandler,
		valueSubmitHandler: titleSubmitHandler,
		defaultValue: titleDefault,
		reset: resetTitle
	} = useInput(isRequired); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	const {
		value: enteredInitials,
		isValid: initialsIsValid,
		hasError: initialsHasError,
		valueChangeHandler: initialsChangedHandler,
		inputBlurHandler: initialsBlurHandler,
		valueSubmitHandler: initialsSubmitHandler,
		defaultValue: initialsDefault,
		reset: resetInitials
	} = useInput(isRequired); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	// const {
	// 	value: enteredExtension,
	// 	isValid: extensionIsValid,
	// 	hasError: extensionHasError,
	// 	valueChangeHandler: extensionChangedHandler,
	// 	inputBlurHandler: extensionBlurHandler,
	// 	valueSubmitHandler: extensionSubmitHandler,
	// 	defaultValue: extensionDefault,
	// 	reset: resetExtension
	// } = useInput(isRequired); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	let formIsValid = false;
	if (titleIsValid && initialsIsValid) { // And any other form fields
		formIsValid = true;
	}

	const submitHandler = event => {
		event.preventDefault();

		setFormProcessing(true);

		// Added (if form is able to be submitted first before error checking)
		titleSubmitHandler();
		initialsSubmitHandler();
		// extensionSubmitHandler();

		if (!formIsValid) {
			setErrorMessage(null);
			setFormProcessing(false);
			return;
		}

		const formData = {
			token: authCtx.token,
			initials: enteredInitials,
			// extension: enteredExtension,
			title: enteredTitle,
			supervisor: supervisorRef.current.value,
			team: teamRef.current.value,
			department: departmentRef.current.value,
			desktop: desktopRef.current.value,
			employee: params.employeeId
		};
		// console.log('FORM DATA');
		// console.log(formData);

		sendRequest(formData);

		setFormProcessing(false);
		// lnameRef.current.value = ''; // Not ideal but doable (because you're directly manipulating the DOM)
	}

	const cancelHandler = () => {
		props.onUpdate();
	}

	const initialsInputClasses = initialsHasError ? 'form-control invalid' : 'form-control';
	const titleInputClasses = titleHasError ? 'form-control invalid' : 'form-control';
	// const extensionInputClasses = extensionHasError ? 'form-control invalid' : 'form-control';

	return (
		<div className="formwrap">
			{
				<p className="error-text">{errorMessage}</p>
			}
			<form onSubmit={submitHandler}>
				{/*
				<div className={extensionInputClasses}>
					<label htmlFor='extension'>extension</label>
					<input
						type="text"
						name="extension"
						id="extension"
						maxLength="3"
						onChange={extensionChangedHandler}
						onBlur={extensionBlurHandler}
						value={enteredExtension}
					/>
					{extensionHasError &&
						<p className="error-text">Required</p>
					}
				</div>
				*/}

				<div className={titleInputClasses}>
					<label htmlFor='title'>title</label>
					<input
						type="text"
						name="title"
						id="title"
						maxLength="50"
						onChange={titleChangedHandler}
						onBlur={titleBlurHandler}
						value={enteredTitle}
					/>
					{titleHasError &&
						<p className="error-text">Required</p>
					}
				</div>
				<div className="customselect">
					<label htmlFor='team'>team</label>
					<select name="team" id="team" ref={teamRef}>
						{teams.map(team =>
							<> 
								{team.teamId != props.data.teamId &&
									<option key={team.teamId} value={team.teamId}>{team.name}</option>
								}
								{team.teamId == props.data.teamId &&
									<option key={team.teamId} value={team.teamId} selected="selected">{team.name}</option>
								}
							</>
						)}
					</select>
				</div>
				<div className="customselect">
					<label htmlFor='department'>department</label>
					<select name="department" id="department" ref={departmentRef}>
						{departments.map(department =>
							<> 
								{department.departmentId != props.data.departmentId &&
									<option key={department.departmentId} value={department.departmentId}>{department.name}</option>
								}
								{department.departmentId == props.data.departmentId &&
									<option key={department.departmentId} value={department.departmentId} selected="selected">{department.name}</option>
								}
							</>
						)}
					</select>
				</div>
				<div className="customselect">
					<label htmlFor='supervisor'>supervisor</label>
					<select name="supervisor" id="supervisor" ref={supervisorRef}>
						{employees.map(emp =>
							<> 
								{emp.employeeId != props.data.supervisorEmployeeId &&
									<option key={emp.employeeId} value={emp.employeeId}>{emp.name}</option>
								}
								{emp.employeeId == props.data.supervisorEmployeeId &&
									<option key={emp.employeeId} value={emp.employeeId} selected="selected">{emp.name}</option>
								}
							</>
						)}
					</select>
				</div>
				<div className={initialsInputClasses}>
					<label htmlFor='initials'>initials</label>
					<input
						type="text"
						name="initials"
						id="initials"
						maxLength="4"
						onChange={initialsChangedHandler}
						onBlur={initialsBlurHandler}
						value={enteredInitials}
					/>
					{initialsHasError &&
						<p className="error-text">Required</p>
					}
				</div>
				<div className="customselect">
					<label htmlFor='desktop'>desktop</label>
					<select name="desktop" id="desktop" ref={desktopRef}>
						{!props.data.pcInd &&
							<>
								<option key="1" value="1">PC</option>
								<option key="0" value="0" selected="selected">Mac</option>
							</>
						}
						{props.data.pcInd &&
							<>
								<option key="1" value="1" selected="selected">PC</option>
								<option key="0" value="0">Mac</option>
							</>
						}
					</select>
				</div>

				<div className="button-wrap">
					{ formProcessing &&
						<div>
							<LoadingSpinner />
						</div>
					}
					{ !formProcessing &&
						<>
							<button type="button" className="secondary" onClick={cancelHandler} disabled={formProcessing}>cancel</button>
							<button type="submit" disabled={formProcessing}>update</button>
						</>
					}
				</div>
			</form>
		</div>
	)
}

export default AddressForm;