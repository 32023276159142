import { useState, useContext, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import AuthContext from '../../../context/auth-context'
import NotificationContext from '../../../context/notification-context';
import LoadingSpinner from '../../UI/LoadingSpinner';
import useInput from '../../../hooks/use-input';
import useHttp from '../../../hooks/use-http';
import { updateActive } from '../../../api/account';
// import TextField from '@material-ui/core/TextField';

const ArchiveForm = (props) => {
	const params = useParams();
	const history = useHistory();

	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);
	const [formProcessing, setFormProcessing] = useState(false);

	let isHR = false;
	if (typeof params.employeeId !== 'undefined') {
		if (!isNaN(params.employeeId) && (params.employeeId > 0)) {
			isHR = true;
		}
	}

	const { sendRequest, status, data, error } = useHttp(updateActive);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					error
				)
			} else if (!data['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					data['status_code'] + ': ' + data['status']
				)
			} else {
				notifyCtx.displayNotification(
					"OK",
					'Account archived'
				)

				// Return to logician lookup
				history.push('/logician-lookup/');
			}
		}
	}, [status, error, data]);

	const submitHandler = event => {
		event.preventDefault();

		setFormProcessing(true);

		let formData = {
			token: authCtx.token
		};

		if (isHR) {
			formData = {
				token: authCtx.token,
				employee: params.employeeId
			};
		}

		sendRequest(formData);

		setFormProcessing(false);
	}

	const cancelHandler = () => {
		props.onUpdate();
	}

	return (
		<div className="formwrap">
			<form onSubmit={submitHandler}>
				<div className="button-wrap">
					{ formProcessing &&
						<div>
							<LoadingSpinner />
						</div>
					}
					{ !formProcessing &&
						<>
							<p>Are you sure you'd like to archive the account for {props.data.name}? The account will no longer be accessible and you'll have to contact the Interactive Servivces department if you'd like to undo this action.</p>
							<button type="button" className="secondary" onClick={cancelHandler} disabled={formProcessing}>cancel</button>
							<button type="submit" disabled={formProcessing}>archive account</button>
						</>
					}
				</div>
			</form>
		</div>
	)
}

export default ArchiveForm;