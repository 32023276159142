const API_BASE_URL = process.env.REACT_APP_PHP_API_BASE_URL;
 
export async function updatePersonalEmail(pageData) {
	const response = await fetch(`${API_BASE_URL}/account/updatePersonalEmail/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not modify the email address.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function updateAddress(pageData) {
	const response = await fetch(`${API_BASE_URL}/account/updateAddress/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not modify the address.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function updatePhone(pageData) {
	const response = await fetch(`${API_BASE_URL}/account/updatePhone/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not modify the phone numbers.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function updatePassword(pageData) {
	const response = await fetch(`${API_BASE_URL}/account/updatePassword/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not modify the password.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function updatePersonalPin(pageData) {
	const response = await fetch(`${API_BASE_URL}/account/updatePersonalPin/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not modify the personal PIN.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function updateBirthdayMessage(pageData) {
	const response = await fetch(`${API_BASE_URL}/account/updateBirthdayMessage/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not toggle the birthday message indicator.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function updatePosition(pageData) {
	const response = await fetch(`${API_BASE_URL}/account/updatePosition/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not modify the position.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function updateTimeZone(pageData) {
	const response = await fetch(`${API_BASE_URL}/account/updateTimeZone/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not modify the time zone or DST setting.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function updateActive(pageData) {
	const response = await fetch(`${API_BASE_URL}/account/updateActive/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not archive the account.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

