const API_BASE_URL = process.env.REACT_APP_PHP_API_BASE_URL;
 
export async function authenticateEmployee(formData) {
//	console.log(process.env.REACT_APP_PHP_API_BASE_URL);
	const response = await fetch(`${API_BASE_URL}/authentication/checkAccess/`, {
		method: 'POST',
		body: JSON.stringify(formData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

//	console.log(response);
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not authenticate.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function checkBrowser(tokenData) {
	const response = await fetch(`${API_BASE_URL}/authentication/checkBrowser/`, {
		method: 'POST',
		body: JSON.stringify(tokenData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not check the browser.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function trustBrowser(formData) {
	const response = await fetch(`${API_BASE_URL}/authentication/checkPin/`, {
		method: 'POST',
		body: JSON.stringify(formData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not trust the browser.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function resendPin(pinData) {
	const response = await fetch(`${API_BASE_URL}/authentication/resendPin/`, {
		method: 'POST',
		body: JSON.stringify(pinData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not resend the PIN.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('RESETPIN DATA');
	// console.log(data);
	return data;
}

export async function checkRememberMe() {
//	console.log(process.env.REACT_APP_PHP_API_BASE_URL);
	const response = await fetch(`${API_BASE_URL}/authentication/checkRememberMe/`, {
		method: 'POST',
		// body: JSON.stringify(formData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

//	console.log(response);
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve personal PIN status.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function checkPersonalPin(formData) {
//	console.log(process.env.REACT_APP_PHP_API_BASE_URL);
	const response = await fetch(`${API_BASE_URL}/authentication/checkPersonalPin/`, {
		method: 'POST',
		body: JSON.stringify(formData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

//	console.log(response);
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not check the personal PIN.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}
	
export async function resetPassword(emailData) {
	const response = await fetch(`${API_BASE_URL}/authentication/resetPassword/`, {
		method: 'POST',
		body: JSON.stringify(emailData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not reset the password.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('RESET PASSWORD DATA');
	// console.log(data);
	return data;
}
