import { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import AuthContext from '../../context/auth-context';
import NotificationContext from '../../context/notification-context';
import useHttp from '../../hooks/use-http';
import { getEmployeeDetails } from '../../api/employee';
import PersonalEmailForm from '../../components/containers/account/PersonalEmailForm';
import AddressForm from '../../components/containers/account/AddressForm';
import PhoneForm from '../../components/containers/account/PhoneForm';
import PasswordForm from '../../components/containers/account/PasswordForm';
import PersonalPinForm from '../../components/containers/account/PersonalPinForm';
import BirthdayMessageForm from '../../components/containers/account/BirthdayMessageForm';
import TimeZoneForm from '../../components/containers/account/TimeZoneForm';
import PositionForm from '../../components/containers/account/PositionForm';
import ArchiveForm from '../../components/containers/account/ArchiveForm';
import LoadingSpinner from '../../components/UI/LoadingSpinner';

const Account = () => {
	// Set header information and body classes
	document.title = "Account | Time Off Tracker";
	document.body.className = "account";

	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);

	const params = useParams();

	const [details, setDetails] = useState([]);
	const [employeeName, setEmployeeName] = useState('');
	const [supervisorName, setSupervisorName] = useState('');
	const [personalEmailForm, setPersonalEmailForm] = useState(false);
	const [addressForm, setAddressForm] = useState(false);
	const [phoneForm, setPhoneForm] = useState(false);
	const [passwordForm, setPasswordForm] = useState(false);
	const [personalPinForm, setPersonalPinForm] = useState(false);
	const [birthdayMessageForm, setBirthdayMessageForm] = useState(false);
	const [timeZoneForm, setTimeZoneForm] = useState(false);
	const [positionForm, setPositionForm] = useState(false);
	const [archiveForm, setArchiveForm] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	
	let requestData = {
		token: authCtx.token
	};
	
	let isHR = false;
	if (typeof params.employeeId !== 'undefined') {
		if (!isNaN(params.employeeId) && (params.employeeId > 0)) {
			isHR = true;
			requestData = {
				token: authCtx.token,
				employee: params.employeeId
			};
		}
	}

	const { sendRequest, status, data, error } = useHttp(getEmployeeDetails, true);
	
	/**
	**********
	INIT
	**********
	*/
	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					error
				)
			} else if (!data['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					data['status_code'] + ': ' + data['status']
				)
			} else {
				// console.log('EMPLOYEE DATA');
				// console.log(data['data']);

				setDetails(data['data']);
				setEmployeeName(data['data']['name'].toLowerCase());
				setSupervisorName(data['data']['supervisor']);
			}
			setIsLoading(false);

			// Reset timeout
			authCtx.updateExpiration();
		}
	}, [status, error, data]);

	useEffect(() => {
		sendRequest(requestData);
	}, [sendRequest]);

	/**
	**********
	HANDLERS
	**********
	*/
	const personalEmailFormHandler = (refresh = false) => {
		setPersonalEmailForm((personalEmailForm) ? false : true);
		if ((refresh === true)) {
			//setIsLoading(true);
			sendRequest(requestData);
		}
	}

	const addressFormHandler = (refresh = false) => {
		setAddressForm((addressForm) ? false : true);
		if ((refresh === true)) {
			//setIsLoading(true);
			sendRequest(requestData);
		}
	}

	const phoneFormHandler = (refresh = false) => {
		setPhoneForm((phoneForm) ? false : true);
		if ((refresh === true)) {
			//setIsLoading(true);
			sendRequest(requestData);
		}
	}

	const passwordFormHandler = () => {
		if (isHR) {
			setPasswordForm((passwordForm) ? false : true);
		}
	}

	const personalPinFormHandler = () => {
		if (isHR) {
			setPersonalPinForm((personalPinForm) ? false : true);
		}
	}

	const birthdayMessageFormHandler = (refresh = false) => {
		// setBirthdayMessageForm((birthdayMessageForm) ? false : true);
		if (!birthdayMessageForm) {
			setBirthdayMessageForm(true);
		}
		if ((refresh === true)) {
			setBirthdayMessageForm(false);
			sendRequest(requestData);
		}
	}

	const timeZoneFormHandler = (refresh = false) => {
		setTimeZoneForm((timeZoneForm) ? false : true);
		if ((refresh === true)) {
			//setIsLoading(true);
			sendRequest(requestData);
		}
	}

	const positionFormHandler = (refresh = false) => {
		setPositionForm((positionForm) ? false : true);
		if ((refresh === true)) {
			//setIsLoading(true);
			sendRequest(requestData);
		}
	}

	const archiveFormHandler = () => {
		setArchiveForm((archiveForm) ? false : true);
		sendRequest(requestData);
	}

	return (
		<>
			<header role="banner">
				<div className="constrained">
					<h1>account</h1>
				</div>
			</header>

			<div className="breadcrumb">
				<div className="constrained">
					<ul>
						{(!isHR) &&
							<>
								<li><Link to="/">home</Link></li>
								<li>account</li>
							</>
						}
						{(isHR) &&
							<>
								<li><Link to="/">home</Link></li>
								<li><Link to="../logician-lookup/">logician lookup</Link></li>
								<li>{employeeName}</li>
							</>
						}
					</ul>
				</div>
			</div>

			<main>
				<div className="constrained">
					<div class="flexwrap singlecolumn">
						{ isLoading &&
							<div>
								<LoadingSpinner />
							</div>
						}
						{ !isLoading && (details.length != 0) &&
							<div className="empdetails">
								
								{(!isHR) &&
									<>
										<h2>change password</h2>
										<p>
											<PasswordForm onUpdate={passwordFormHandler} />
										</p>
										<h2>change personal PIN</h2>
										<p>
											<PersonalPinForm onUpdate={personalPinFormHandler} />
										</p>
									</>
								}

								{(isHR) &&
									<>
										<h2>{employeeName}</h2>
								
										<p>
											work email:<br />
											<a href={'mailto:' + details.emailAddress} className="emailicon">{details.emailAddress}</a>
										</p>
										<br />

										{!personalEmailForm &&
											<p>
												personal email:<br />
												<button type="button" class="edit" id="prevuseyear" onClick={personalEmailFormHandler}>modify</button>

												{(details.personalEmailAddress != null) && (details.personalEmailAddress != '') &&
													<a href={'mailto:' + details.personalEmailAddress}>{details.personalEmailAddress}</a>
												}
											</p>
										}
										{personalEmailForm &&
											<p>
												personal email:<br />
												<PersonalEmailForm data={details} onUpdate={personalEmailFormHandler} />
											</p>
										}
										<br />

										{!addressForm &&
											<p>
												location:<br />
												<button type="button" class="edit" id="prevuseyear" onClick={addressFormHandler}>modify</button>

												{(details.address != null) && (details.address != '') &&
													<>
														<b>{details.address}</b><br />
													</>
												}
												{(details.address2 != null) && (details.address2 != '') &&
													<>
														<b>{details.address2}</b><br />
													</>
												}
												{(details.city != null) && (details.city != '') &&
													<>
														<b>{details.city}{(details.stateAbbr != null) && ', '}</b>
													</>
												}
												{(details.stateAbbr != null) && (details.stateAbbr != '') &&
													<>
														<b>{details.stateAbbr + ' '}</b>
													</>
												}
												{(details.zip != null) && (details.zip != '') &&
													<>
														<b>{details.zip}</b>
													</>
												}
											</p>
										}
										{addressForm &&
											<p>
												location:<br />
												<AddressForm data={details} onUpdate={addressFormHandler} />
											</p>
										}
										<br />

										<p>
										{!phoneForm &&
											<p>
												phone:<br />
												<button type="button" class="edit" id="prevuseyear" onClick={phoneFormHandler}>modify</button>
												
												{(details.mobilePhone != null) &&
													<>
														cell:<br />
														<a href={'tel:1' + details.mobilePhone} className="cellicon">
															({details.mobilePhone.substring(0, 3)}) {details.mobilePhone.substring(3, 6)}-{details.mobilePhone.substring(6)}
														</a><br />
													</>
												}
												{(details.homePhone != null) &&
													<>
														<br />home:<br />
														<a href={'tel:1' + details.homePhone} className="phoneicon">
															({details.homePhone.substring(0, 3)}) {details.homePhone.substring(3, 6)}-{details.homePhone.substring(6)}
														</a><br />
													</>
												}
												{(details.workPhone != null) &&
													<>
														<br />work:<br />
														<a href={'tel:1' + details.workPhone} className="phoneicon">
															({details.workPhone.substring(0, 3)}) {details.workPhone.substring(3, 6)}-{details.workPhone.substring(6)}
														</a>
													</>
												}
											</p>
										}
										</p>
										{phoneForm &&
											<p>
												phone:<br />
												<PhoneForm data={details} onUpdate={phoneFormHandler} />
											</p>
										}
										<br />

										{!birthdayMessageForm &&
											<p>
												birthday:<br />
												<button type="button" class="edit" id="prevuseyear" onClick={birthdayMessageFormHandler}>modify</button>
												
												{(details.birthdayDisplay != null) &&
													<b>{details.birthdayDisplay}<br /></b>
												}
												Send announcement of your birthday to co-workers: <b>{(details.birthdayMessageInd) ? 'On' : 'Off'}</b>
											</p>
										}
										{birthdayMessageForm &&
											<p>
												birthday:<br />
												<BirthdayMessageForm data={details} onUpdate={birthdayMessageFormHandler} />
											</p>
										}
										<br />

										{!timeZoneForm &&
											<p>
												time zone:<br />
												<button type="button" class="edit" id="prevuseyear" onClick={timeZoneFormHandler}>modify</button>
												
												time zone: <b>{details.timeZone}</b><br />
												observe daylight saving time: <b>{(details.useDST) ? 'Yes' : 'No'}</b>
											</p>
										}
										{timeZoneForm &&
											<p>
												time zone:<br />
												<TimeZoneForm data={details} onUpdate={timeZoneFormHandler} />
											</p>
										}
										<br />

										{!positionForm &&
											<>
												<h3>@ml:</h3>
												{isHR && (supervisorName !== null) && (supervisorName.length > 0) && 
													<>
														<button type="button" class="edit" id="prevuseyear" onClick={positionFormHandler}>modify</button>

													</>
												}
												<p>
													{/* extension: <b>{details.phoneExtension}</b><br /> */}
													title: <b>{details.title}</b><br />
													team: <b>{details.team}</b><br />
													department: <b>{details.department}</b><br />
													{(supervisorName !== null) && (supervisorName.length > 0) && (
														<>
															supervisor: <b>{details.supervisor}</b><br />
														</>
													)}
													initials: <b>{details.initials}</b><br />
													desktop: <b>{details.pcInd == 1 && "PC"}{details.pcInd == 0 && "Mac"}</b><br />
													start date: <b>{details.startDateDisplay}</b>
												</p>
											</>
										}
										{positionForm &&
											<>
												<h3>@ml:</h3>
												<PositionForm data={details} onUpdate={positionFormHandler} />
											</>
										}
										<br />

										{!archiveForm && isHR &&
											<p>
												this employee is <b>ACTIVE</b><br />
												<button type="button" class="edit" id="prevuseyear" onClick={archiveFormHandler}>archive account</button>
											</p>
										}
										{archiveForm &&
											<p>
												<ArchiveForm data={details} onUpdate={archiveFormHandler} />
											</p>
										}
									</>
								}
							</div>
						}
					</div>
				</div>
			</main>
		</>
	)
}

export default Account;