const API_BASE_URL = process.env.REACT_APP_PHP_API_BASE_URL;

export async function getWeather(pageData) {
	// console.log('PAGEDATA:');
	// console.log(pageData);
	const response = await fetch(`${API_BASE_URL}/header/getWeather/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	// Check for unsuccessful response
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the weather.');
	}

	const data = await response.json();

	// const transformedQuotes = [];

	// for (const key in data) {
	// 	const quoteObj = {
	// 		id: key,
	// 		...data[key],
	// 	};

	// 	transformedQuotes.push(quoteObj);
	// }

	return data;
}