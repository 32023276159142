import { useState, useContext, useEffect } from 'react';
import AuthContext from '../../../context/auth-context'
import NotificationContext from '../../../context/notification-context';
import LoadingSpinner from '../../UI/LoadingSpinner';
import useInput from '../../../hooks/use-input';
import useHttp from '../../../hooks/use-http';
import { resetPassword } from '../../../api/authentication';
// import TextField from '@material-ui/core/TextField';

const ForgotPasswordForm = (props) => {
	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);
	const [formProcessing, setFormProcessing] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	const { sendRequest, status, data, error } = useHttp(resetPassword);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					error
				)
			} else if (!data['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					data['status_code'] + ': ' + data['status']
				)
			} else {
				if (data['status_code'] == 200) {
					// Password reset
					notifyCtx.displayNotification(
						"OK",
						'The password has been reset and sent to the email address provided'
					)

					resetEmail();

					// Return to log in
					props.onRequest(false);
				} else {
					setErrorMessage(data['status']);
				}
			}
		}
	}, [status, error, data]);

	// Requirement types
	const isEmail = value => value.includes('@medialogic.com');

	const {
		value: enteredEmail,
		isValid: emailIsValid,
		hasError: emailHasError,
		valueChangeHandler: emailChangedHandler,
		inputBlurHandler: emailBlurHandler,
		valueSubmitHandler: emailSubmitHandler,
		reset: resetEmail
	} = useInput(isEmail); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	let formIsValid = false;
	if (emailIsValid) { // And any other form fields
		formIsValid = true;
	}

	const submitHandler = event => {
		event.preventDefault();

		setFormProcessing(true);

		// Added (if form is able to be submitted first before error checking)
 		emailSubmitHandler();

		if (!formIsValid) {
			setErrorMessage(null);
			setFormProcessing(false);
			return;
		}

		const formData = {
			email: enteredEmail
		};

		sendRequest(formData);

		// props.onAddTest(testEntry);

		// Reset values
		// resetEmail();
		
		setFormProcessing(false);
		// lnameRef.current.value = ''; // Not ideal but doable (because you're directly manipulating the DOM)
	}

	const backHandler = () => {
		props.onRequest(false);
	}

	const emailInputClasses = emailHasError ? 'form-control invalid' : 'form-control';

	return (
		<div className="formwrap">
			{
				<p className="instructions">Your password will be reset for the Time Off Tracker, Annex, Client Portal, and ContentHub and sent to the address provided</p>
			}
			{
				<p className="error-text">{errorMessage}</p>
			}
			<form onSubmit={submitHandler}>
				<div className={emailInputClasses}>
					<label htmlFor='email'>email</label>
					<input
						type="email"
						name="email"
						id="email"
						onChange={emailChangedHandler}
						onBlur={emailBlurHandler}
						value={enteredEmail}
					/>
					{emailHasError &&
						// <p className="error-text">Email must include @.</p>
						<p className="error-text">Valid email address required.</p>
					}
				</div>
				<div className="button-wrap">
					<p><a onClick={backHandler}>log in</a></p>
					{ formProcessing &&
						<div>
							<LoadingSpinner />
						</div>
					}
					{ !formProcessing &&
						<button type="submit" disabled={formProcessing}>reset password</button>
					}
				</div>
			</form>
		</div>
	)
}

export default ForgotPasswordForm;