/**
***************************************************************************
* NAME 			: PendingApproval
* PROPS	 		: data (array)
*				: - id (employeeId)
*				: - name (employee name)
*				: - requestId (timeOffRequestId)
*		 		: onChangeApprovals (approvalHandler)
* RETURNS 		: The details of the time off request
* LAST MODIFIED 	: February 17, 2022
***************************************************************************
*/

import { useState, useEffect, useContext } from 'react';
import AuthContext from '../../../context/auth-context';
import NotificationContext from '../../../context/notification-context';
import useHttp from '../../../hooks/use-http';
import { getPendingApproval, updateRequest, removeRequest } from '../../../api/time-off-tracker';
import LoadingSpinner from '../../UI/LoadingSpinner';
import Modal from '../../UI/Modal';

const PendingApproval = (props) => {
	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);

	const [request, setRequest] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState(null); // Not currently used for display
	const [approveConfirmation, setApproveConfirmation] = useState();
	const [rejectConfirmation, setRejectConfirmation] = useState();

	const requestData = {
		token: authCtx.token,
		id: props.data.requestId
	};

	const { sendRequest, status, data, error } = useHttp(getPendingApproval, true);
	const { sendRequest: approveRequest, status: approveStatus, data: approveData, error: approveError } = useHttp(updateRequest, true);
	const { sendRequest: rejectRequest, status: rejectStatus, data: rejectData, error: rejectError } = useHttp(removeRequest, true);

	/**
	**********
	INIT
	**********
	*/
	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					error
				)
				setErrorMessage(error);
			} else if (!data['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					data['status_code'] + ': ' + data['status']
				)
			} else {
				// console.log('REQUEST DATA');
				// console.log(data['data']);
				setRequest(data['data']);
			}
			setIsLoading(false);
		}
	}, [status, error, data]);

	useEffect(() => {
		// const requestData = {
		// 	token: authCtx.token,
		// 	id: props.data.requestId
		// };
		sendRequest(requestData);
	}, [sendRequest]);

	/**
	**********
	APPROVE
	**********
	*/
	useEffect(() => {
		if (approveStatus === 'completed') {
			if (approveError !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					approveError
				)
				setErrorMessage(error);
			} else if (!approveData['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					approveData['status_code'] + ': ' + approveData['status']
				)
			} else {
				notifyCtx.displayNotification(
					"OK",
					"Time off approved for " +  props.data.name
				)
				props.onChangeApprovals();
			}
		}
	}, [approveStatus, approveError, approveData]);

	const approveConfirmationHandler = () => {
		setApproveConfirmation({
			title: 'approve time off',
			message: "Are you sure you'd like to approve the time off for " +  props.data.name + "?",
			buttonLabel: "approve"
		});
	};

	const approveHandler = () => {
		setApproveConfirmation(null);

		approveRequest(requestData);
	};

	/**
	**********
	REJECT
	**********
	*/
	useEffect(() => {
		if (rejectStatus === 'completed') {
			if (rejectError !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					rejectError
				)
				setErrorMessage(error);
			} else if (!rejectData['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					rejectData['status_code'] + ': ' + rejectData['status']
				)
			} else {
				notifyCtx.displayNotification(
					"OK",
					"Time off rejected for " +  props.data.name
				)
				props.onChangeApprovals();
			}
		}
	}, [rejectStatus, rejectError, rejectData]);

	const rejectConfirmationHandler = () => {
		setRejectConfirmation({
			title: "reject time off",
			message: "Are you sure you'd like to reject the time off for " +  props.data.name + "?",
			buttonLabel: "reject"
		});
	};

	const rejectHandler = () => {
		setRejectConfirmation(null);

		rejectRequest(requestData);
	};

	/**
	**********
	CANCEL
	**********
	*/
	const cancelHandler = () => {
		setApproveConfirmation(null);
		setRejectConfirmation(null);
	};

	return (
		<>
			{ isLoading &&
				<div>
					<LoadingSpinner />
				</div>
			}
			{ approveConfirmation &&
				<div>
					<Modal data={approveConfirmation} onConfirm={approveHandler} onCancel={cancelHandler} />
				</div>
			}
			{ rejectConfirmation &&
				<div>
					<Modal data={rejectConfirmation} onConfirm={rejectHandler} onCancel={cancelHandler} />
				</div>
			}
			{ !isLoading &&
				<div class="pageitem">
					<h3>{props.data.name.toLowerCase()}</h3>
					{
						request.map(info => (
							<div key={info.day} className={'info-item tot ' + info.typeAttr + ' ' + info.classAttr}>
								<p className="time-type">{info.type}</p>
								<p className="title"><time datetime={info.timeAttr}> {info.day} </time></p>
							</div>
						))
					}
					<div className="button-wrap">
						<button type="button" className="secondary" id="reject" onClick={rejectConfirmationHandler} value={props.data.requestId}>reject</button>
						<button type="button" id="approve" onClick={approveConfirmationHandler} value={props.data.requestId}>approve</button><br />
					</div>
				</div>	
			}
		</>
	)
}

export default PendingApproval;