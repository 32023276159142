/**
***************************************************************************
* NAME 			: PeopleOut
* PROPS	 		: None
* RETURNS 		: People out by week (first) and day
* LAST MODIFIED 	: June 2, 2022
***************************************************************************
*/

import { useState, useEffect, useContext } from 'react';
// import AuthContext from '../../../context/auth-context';
import useHttp from '../../../hooks/use-http';
import { getPeopleOut } from '../../../api/time-off-tracker';
import LoadingSpinner from '../../UI/LoadingSpinner';

const PeopleOut = () => {
	// const authCtx = useContext(AuthContext);

	const [isLoading, setIsLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState(null);
	let peopleOut = "";

	const { sendRequest, status, data, error } = useHttp(getPeopleOut, true);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				setErrorMessage(error);
			} else if (!data['success']) {
				setErrorMessage(data['status_code'] + ': ' + data['status']);
			} else {
				// console.log('PEOPLE OUT DATA');
				// console.log(data['data']);
				if (data.data.length > 0) {
					data.data.map(info =>
						peopleOut = peopleOut + '<div className="info-item ' + info.classAttr + '"><time datetime="' + info.timeAttr + '">' + info.date + '</time><p className="title">' + info.day + ':</p><div className="info"><p>' + info.peopleOutList + '</p></div></div>'
					)
				} else {
					peopleOut = 'No details to display'
				}
			}
			setIsLoading(false);
		}
	}, [status, error, data]);

	useEffect(() => {
		// const requestData = {
		// 	token: authCtx.token
		// };
		// sendRequest(requestData);
		sendRequest();
	}, [sendRequest]);

	return (
		<div className="pageitem column people-out">
			<h2>people out</h2>
			
			<div className="flexwrap">
				{
					isLoading && (
						<div>
							<LoadingSpinner />
						</div>
					)
				}
				{
					!isLoading && (
						<>
							{ errorMessage }

							{ !errorMessage &&
								(data.data.length > 0) ?
									data.data.map(info =>
										<div key={info.day} className={info.classAttr}><time datetime={info.timeAttr}>{info.date}</time><p className="title">{info.day}:</p><div className="info"><p>{info.peopleOutList}</p></div></div>
									)
								: 'No details to display'
							}
						</>
					)
				}
			</div>
		</div>
	)
}

export default PeopleOut;
