const DirectContainer = (props) => {
	console.log(props);

	let result = props.content;

	return (
		<div>{console.log('TEST: ' + result)}
			{
				result.map(info =>
					<div key={Math.random()}>
						<strong>{info.testId}</strong>: {info.testField}<br />
					</div>
				)
			}
		</div>
	);
};

export default DirectContainer;