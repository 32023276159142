/**
***************************************************************************
* NAME 			: Calendar
* PROPS	 		: data (array - getYearSummary data)
*				: type (array) (optional) (makes days active for selection)
*				: - timeOffTypeId
*				: - name
*				: - class
*				: employee (optional) (used for HR to view employee's time)
*				: - employeeId
*				: - firstName
*				: - lastName
*				: - name
*				: onSelectDay (selectDayHandler) (optional)
*				: insideForm (1 || 0 = default) 
*				: - specifies in the calendar is wrapped in a form
*				: isLoading (summaryStatus === 'pending')
* RETURNS 		: Calendar to review and select time off
* LAST MODIFIED 	: May 19, 2022
***************************************************************************
*/

import { useState, useEffect, useContext, useRef } from 'react';
// import { Link } from 'react-router-dom';
import AuthContext from '../../../context/auth-context';
import NotificationContext from '../../../context/notification-context';
import useHttp from '../../../hooks/use-http';
import { getCalendarFilters, getCalendar } from '../../../api/time-off-tracker';
import CalendarGrid from './CalendarGrid';

const Calendar = (props) => {
	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);

	const [filterOptions, setFilterOptions] = useState([]);
	const [calendar, setCalendar] = useState([]);
	const [currentDate, setCurrentDate] = useState(null);
	const [previousMonth, setPreviousMonth] = useState(null);
	const [nextMonth, setNextMonth] = useState(null);
	const [monthDisplay, setMonthDisplay] = useState(null);
	const [monthDisabled, setMonthDisabled] = useState(true);
	const [isLoading, setIsLoading] = useState(true);

	const filterRef = useRef('');

	let type = [];
	if (typeof props.type !== 'undefined') {
		if (typeof props.type.timeOffTypeId !== 'undefined') {
			type = props.type;
		}
	}

	let insideForm = false;
	if (typeof props.insideForm !== 'undefined') {
		if (props.insideForm == "1") {
			insideForm = true;
		}
	}

	let isHR = false;
	let filterRequestData = {
		token: authCtx.token
	};
	let calendarRequestData = {
		token: authCtx.token
	};
	if (typeof props.employee !== 'undefined') {
		if (!isNaN(props.employee.employeeId)) {
			isHR = true;
			filterRequestData = {
				token: authCtx.token,
				employeeId: props.employee.employeeId
			};
			calendarRequestData = {
				token: authCtx.token,
				employee: props.employee.employeeId
			};
		}
	}

	const { sendRequest, status, data, error } = useHttp(getCalendarFilters, true);
	const { sendRequest: calendarRequest, status: calendarStatus, data: calendarData, error: calendarError } = useHttp(getCalendar, true);

	/**
	**********
	INIT
	**********
	*/
	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					error
				)
			} else if (!data['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					data['status_code'] + ': ' + data['status']
				)
			} else {
				// console.log('FILTER OPTIONS');
				// console.log(data['data']);

				setFilterOptions(data['data']);
			}
			// setIsLoading(false);
		}
	}, [status, error, data]);

	useEffect(() => {
		if (calendarStatus === 'completed') {
			if (calendarError !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					calendarError
				)
			} else if (!calendarData['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					calendarData['status_code'] + ': ' + calendarData['status']
				)
			} else {
				// console.log('CALENDAR DATA');
				// console.log(calendarData['data']);

				setCalendar(calendarData['data']);
				setMonthDisplay(calendarData['data']['month']['display']);
				setCurrentDate(calendarData['data']['month']['selectedDate']);
				setPreviousMonth(calendarData['data']['month']['previous']);
				setNextMonth(calendarData['data']['month']['next']);
				setMonthDisabled(false);
			}
			// setIsLoading(false);
		}
	}, [calendarStatus, calendarError, calendarData]);

	useEffect(() => {
		sendRequest(filterRequestData);
		calendarRequest(calendarRequestData);
	}, [sendRequest, calendarRequest]);

	/**
	**********
	HANDLERS
	**********
	*/
	function filterHandler(event) {
		event.preventDefault();

		setMonthDisabled(true);

		let filterData = {
			token: authCtx.token,
			selectedDate: currentDate
		};

		if (filterRef.current.value > 0) {
			filterData.team = parseInt(filterRef.current.value);
		} else if (filterRef.current.value < 0) {
			filterData.employee = parseInt(filterRef.current.value) * -1;
		}

		// console.log(filterData);

		calendarRequest(filterData);
	}

	function monthHandler(event) {
		event.preventDefault();

		setMonthDisabled(true);

		let requestData = {
			token: authCtx.token
		};

		if (event.target.value == "previous") {
			// Previous
			requestData.selectedDate = previousMonth;
		} else {
			// Next
			requestData.selectedDate = nextMonth;
		}
		if (filterRef.current.value > 0) {
			requestData.team = parseInt(filterRef.current.value);
		} else if (filterRef.current.value < 0) {
			requestData.employee = parseInt(filterRef.current.value) * -1;
		}

		// console.log(requestData);
		calendarRequest(requestData);
	}

	function selectDayHandler(selectDayData) {
		props.onSelectDay(selectDayData);
	}


	return (
		<div class="pageitem calendar">
			<h2>calendar</h2>
			<div class="filters">
				{!insideForm &&
					<form action="">
						<div class="customselect">
							<label for="cal-filter">filter</label>
							<select name="calendarfilter" id="cal-filter" onChange={filterHandler} disabled={monthDisabled} ref={filterRef}>
								{
									filterOptions.map(filter =>
										<option key={filter.value} value={filter.value}>{filter.name}</option>
									)
								}
							</select>
						</div>
					</form>	
				}

				{insideForm &&
					<div class="customselect">
						<label for="cal-filter">filter</label>
						<select name="calendarfilter" id="cal-filter" onChange={filterHandler} disabled={monthDisabled} ref={filterRef}>
							{
								filterOptions.map(filter =>
									<option key={filter.value} value={filter.value}>{filter.name}</option>
								)
							}
						</select>
					</div>
				}

				<div class="select-month">
					
					<button type="button" class="prev" id="prevmonth" onClick={monthHandler} value="previous" disabled={monthDisabled}>show previous month</button>
					
					<div class="month-info">
						<h3>{monthDisplay}</h3>
					</div>
					
					<button type="button" class="next" id="nextmonth" onClick={monthHandler} value="next" disabled={monthDisabled}>show next year</button>
				</div>
			</div>
			<div class="wrapper">
				{!isHR && (typeof type.timeOffTypeId === 'undefined') &&
					<CalendarGrid data={calendar['days']} isLoading={calendarStatus === 'pending'} />
				}
				{!isHR && (typeof type.timeOffTypeId !== 'undefined') &&
					<CalendarGrid data={calendar['days']} type={type} onSelectDay={selectDayHandler} isLoading={calendarStatus === 'pending'} />
				}
				{isHR && (typeof type.timeOffTypeId === 'undefined') &&
					<CalendarGrid data={calendar['days']} employee={props.employee} isLoading={calendarStatus === 'pending'} />
				}
				{isHR && (typeof type.timeOffTypeId !== 'undefined') &&
					<CalendarGrid data={calendar['days']} type={type} employee={props.employee} onSelectDay={selectDayHandler} isLoading={calendarStatus === 'pending'} />
				}
			</div>
		</div>
	)
}

export default Calendar;
