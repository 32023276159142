import { useState, useContext, useEffect } from 'react';
import AuthContext from '../../../context/auth-context'
import NotificationContext from '../../../context/notification-context';
import LoadingSpinner from '../../UI/LoadingSpinner';
import useInput from '../../../hooks/use-input';
import useHttp from '../../../hooks/use-http';
import { updatePersonalPin } from '../../../api/account';
// import TextField from '@material-ui/core/TextField';

const PersonalPinForm = (props) => {
	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);
	const [formProcessing, setFormProcessing] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	const { sendRequest, status, data, error } = useHttp(updatePersonalPin);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					error
				)
			} else if (!data['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					data['status_code'] + ': ' + data['status']
				)
			} else {
				if (data['status_code'] == 200) {
					// PIN reset
					let action = 'modified';
					if (enteredPin.length == 0) {
						action = 'removed';
					}

					notifyCtx.displayNotification(
						"OK",
						'Your PIN has been ' + action
					)

					// Reset form values and error message
					setErrorMessage(null);
					resetPassword();
					resetPin();
					resetConfirmPin();

					// Close (if HR)
					props.onUpdate();
				} else {
					setErrorMessage(data['status']);
				}
			}
		}
	}, [status, error, data]);

	// Requirement types
	const isPassword = value => value.length >= 8;
	const isPIN = value => value.match(/^[0-9]*$/) && (value.length == 0 || (value.length >= 4) && (value.length <= 6));
	const isConfirmPIN = value => value == enteredPin;

	const {
		value: enteredPassword,
		isValid: passwordIsValid,
		hasError: passwordHasError,
		valueChangeHandler: passwordChangedHandler,
		inputBlurHandler: passwordBlurHandler,
		valueSubmitHandler: passwordSubmitHandler,
		reset: resetPassword
	} = useInput(isPassword); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	const {
		value: enteredPin,
		isValid: pinIsValid,
		hasError: pinHasError,
		valueChangeHandler: pinChangedHandler,
		inputBlurHandler: pinBlurHandler,
		valueSubmitHandler: pinSubmitHandler,
		reset: resetPin
	} = useInput(isPIN); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	const {
		value: enteredConfirmPin,
		isValid: confirmPinIsValid,
		hasError: confirmPinHasError,
		valueChangeHandler: confirmPinChangedHandler,
		inputBlurHandler: confirmPinBlurHandler,
		valueSubmitHandler: confirmPinSubmitHandler,
		reset: resetConfirmPin
	} = useInput(isConfirmPIN); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	let formIsValid = false;
	if (passwordIsValid && pinIsValid && confirmPinIsValid) { // And any other form fields
		formIsValid = true;
	}

	const submitHandler = event => {
		event.preventDefault();

		setFormProcessing(true);

		// Added (if form is able to be submitted first before error checking)
 		passwordSubmitHandler();
 		pinSubmitHandler();
 		confirmPinSubmitHandler();

		if (!formIsValid) {
			setErrorMessage(null);
			setFormProcessing(false);
			return;
		}

		let formData = {
			token: authCtx.token,
			password: enteredPassword,
			pin: enteredPin,
			confirmpin: enteredConfirmPin
		};
		// console.log('FORMDATA');
		// console.log(formData);

		sendRequest(formData);

		// Reset values
		// resetPassword();
		// resetNewPassword();
		// resetConfirmPassword();

		setFormProcessing(false);
		// lnameRef.current.value = ''; // Not ideal but doable (because you're directly manipulating the DOM)
	}

	const cancelHandler = () => {
		props.onUpdate();
	}

	const passwordInputClasses = passwordHasError ? 'form-control invalid' : 'form-control';
	const pinInputClasses = pinHasError ? 'form-control invalid' : 'form-control';
	const confirmPinInputClasses = confirmPinHasError ? 'form-control invalid' : 'form-control';

	return (
		<div className="formwrap">
			<p className="instructions">With your personal PIN set, you add the ability to simply utilize your PIN when logging in from a previously recognized browser. To remove this feature, enter and submit nothing for your PIN.</p>
			{
				<p className="error-text">{errorMessage}</p>
			}
			<form onSubmit={submitHandler}>
				<div className={passwordInputClasses}>
					<label htmlFor='password'>current password</label>
					<input
						type="password"
						name="password"
						id="password"
						onChange={passwordChangedHandler}
						onBlur={passwordBlurHandler}
						value={enteredPassword}
					/>
					{passwordHasError &&
						<p className="error-text">Valid password required.</p>
					}
				</div>
				<div className={pinInputClasses}>
					<label htmlFor='pin'>new PIN</label>
					<input
						type="password"
						name="pin"
						id="pin"
						onChange={pinChangedHandler}
						onBlur={pinBlurHandler}
						value={enteredPin}
					/>
					{pinHasError &&
						// <p className="error-text">Email must include @.</p>
						<p className="error-text">The new PIN must be between 4 and 6 digits or blank to disable the feature</p>
					}
				</div>
				<div className={confirmPinInputClasses}>
					<label htmlFor='confirmPin'>confirm new PIN</label>
					<input
						type="password"
						name="confirmPin"
						id="confirmPin"
						onChange={confirmPinChangedHandler}
						onBlur={confirmPinBlurHandler}
						value={enteredConfirmPin}
					/>
					{confirmPinHasError &&
						// <p className="error-text">Email must include @.</p>
						<p className="error-text">Confirm PIN must match the PIN</p>
					}
				</div>
				<div className="button-wrap">
					{ formProcessing &&
						<div>
							<LoadingSpinner />
						</div>
					}
					{ !formProcessing &&
						<>
							<button type="button" className="secondary" onClick={cancelHandler} disabled={formProcessing}>cancel</button>
							<button type="submit" disabled={formProcessing}>update</button>
						</>
					}
				</div>
			</form>
		</div>
	)
}

export default PersonalPinForm;